import React, { useEffect, useMemo } from "react";
import { RenderItemV2 } from "./RenderItemV2";

export const OptionLayer = ({
  optionData = [],
  selectedIpIds,
  setSelectedIpIds,
  setSplitPanelStatus,
}) => {

  return (
    <>
      <div className="@container">
        <div className="gap-4 w-full h-full grid grid-cols-1 @sm:grid-cols-2 @lg:grid-cols-3 @2xl:grid-cols-4 @4xl:grid-cols-5 @6xl:grid-cols-6 @7xl:grid-cols-7 @8xl:grid-cols-8 @9xl:grid-cols-9 @10xl:grid-cols-10 @11xl:grid-cols-11 @12xl:grid-cols-12 @13xl:grid-cols-13 @14xl:grid-cols-14">
          {!Array.isArray(optionData) || optionData.length <= 0 ? (
            <div></div>
          ) : (
            optionData.map(item => (
              <RenderItemV2
                key={item.ip_id}
                item={item}
                selected={selectedIpIds?.includes(item.ip_id) ?? false}
                onSelectedChanged={(checked) => {
                  const newSelectedIpIds = checked
                    ? [...selectedIpIds, item.ip_id]
                    : selectedIpIds.filter((id) => id !== item.ip_id);
                  setSelectedIpIds(newSelectedIpIds);
                  if (checked) {
                    setSplitPanelStatus(true);
                  }
                }}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};
