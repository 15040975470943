import { Box, Container, ExpandableSection, Grid, SpaceBetween } from "@cloudscape-design/components";
import React, { FC } from "react";
import { DateRange } from "../DateRange";
import { WikipediaTrends } from "./WikipediaTrends/WikipediaTrends";
import { YoutubeTrends } from "./YoutubeTrends/YoutubeTrends";
import { ImdbTrends } from "./ImdbTrends/ImdbTrends";
import { TwitchTrends } from "./TwitchTrends/TwitchTrends";
import { SteamTrends } from "./SteamTrends/SteamTrends";
import { isDataAvailable } from "../../../utils";

interface RankTrendsProps {
  dateRange: any;
  setDateRange: (range: [Date, Date]) => void;
  wikipediaTrendsData: any;
  youtubeTrendsData: any;
  imdbTrendsData: any;
  twitchTrendsData: any;
  steamTrendsData: any;
}

export const RankTrends: FC<RankTrendsProps> = ({
  dateRange,
  setDateRange,
  wikipediaTrendsData,
  youtubeTrendsData,
  imdbTrendsData,
  twitchTrendsData,
  steamTrendsData,
}) => {
  const hasTwitchData = twitchTrendsData !== undefined && 
    isDataAvailable(twitchTrendsData) && 
    (Array.isArray(twitchTrendsData) ? twitchTrendsData.length > 0 : Object.keys(twitchTrendsData).length > 0);

  const hasSteamData = steamTrendsData !== undefined && 
    isDataAvailable(steamTrendsData) && 
    (Array.isArray(steamTrendsData) ? steamTrendsData.length > 0 : Object.keys(steamTrendsData).length > 0);

  return (
    <ExpandableSection
      defaultExpanded={true}
      headerText="Ranking Trends"
      headerActions={
        <DateRange dateRange={dateRange} setDateRange={setDateRange} />
      }
    >
      <Grid
        gridDefinition={[
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
        ]}
      >
        {isDataAvailable(wikipediaTrendsData) && (
          <WikipediaTrends
            data={wikipediaTrendsData}
            dateRange={dateRange}
            configType="basic"
          />
        )}
        {isDataAvailable(youtubeTrendsData) && (
          <YoutubeTrends
            data={youtubeTrendsData}
            dateRange={dateRange}
            configType="basic"
          />
        )}
        {isDataAvailable(imdbTrendsData) && (
          <ImdbTrends
            data={imdbTrendsData}
            dateRange={dateRange}
            configType="basic"
          />
        )}
        {hasTwitchData && (
          <TwitchTrends
            data={twitchTrendsData}
            dateRange={dateRange}
            configType="basic"
          />
        )}
        {hasSteamData && (
          <SteamTrends
            data={steamTrendsData}
            dateRange={dateRange}
            configType="basic"
          />
        )}
      </Grid>
    </ExpandableSection>
  );
};
