import React from "react";
import moment from "moment";
import { Box, ButtonDropdown, Checkbox } from "@cloudscape-design/components";

const TableOptions = ({
  tableRef,
  metrics,
  heatmapEnabled,
  setHeatmapEnabled,
}) => {

  return (
    <Box float="right">
      <div className="flex gap-x-2 items-center">
        <Checkbox
          checked={heatmapEnabled}
          onChange={({ detail }) => setHeatmapEnabled(detail.checked)}
        >
          Heatmap
        </Checkbox>
        <ButtonDropdown
          items={[
            { text: "Table data as CSV", id: "download_data_csv" },
            { text: "Table data as XLSX", id: "download_data_xlsx" },
          ]}
          onItemClick={({ detail }) => {
            switch (detail.id) {
              case "download_data_csv":
                tableRef.current.api.exportDataAsCsv({
                  fileName: `compare_${moment
                    .utc()
                    .format("YYYY-MM-DDTHH:mm:ss")}`,
                  columnKeys: [
                    "title",
                    ...metrics
                      .map((metric) => [
                        `${metric.key}_average`,
                        `${metric.key}_max`,
                        `${metric.key}_sum`,
                      ])
                      .flat(),
                  ],
                });
                break;
              case "download_data_xlsx":
                tableRef.current.api.exportDataAsExcel({
                  fileName: `compare_${moment
                    .utc()
                    .format("YYYY-MM-DDTHH:mm:ss")}`,
                  columnKeys: [
                    "title",
                    ...metrics
                      .map((metric) => [
                        `${metric.key}_average`,
                        `${metric.key}_max`,
                        `${metric.key}_sum`,
                      ])
                      .flat(),
                  ],
                });
                break;
            }
          }}
        >
          Download
        </ButtonDropdown>
      </div>
    </Box>
  );
};

export default TableOptions;