import React, { useEffect, useState } from "react";
import { Autosuggest } from "@cloudscape-design/components";
import { useGetTitleSearchData } from "./api/hooks/useGetTitleSearchData";
import { convertData } from "../../utils";

const TitleSelector = ({ onSelectedItemChanged, defaultSelectedTitle=null, searchQuery, setSearchQuery }) => {

  const [ searchResults, setSearchResults ] = useState([]);
  const [ selectedItem, setSelectedItem ] = useState(null);
  const [ queryString, setQueryString ] = useState("");

  const {
    data: titleSearchData,
    isLoading,
  } = useGetTitleSearchData({ queryString: queryString });

  useEffect(() => {
    if (defaultSelectedTitle) {
      const convertedItems = convertData(defaultSelectedTitle);
      setSelectedItem(convertedItems[0]);
      onSelectedItemChanged(convertedItems[0]);
    }
  }, [defaultSelectedTitle]);

  useEffect(() => {
    if (titleSearchData) {
      setSearchResults(titleSearchData);
    }
  }, [titleSearchData]);

  return (
    <Autosuggest
      options={(searchResults && searchResults?.length > 0) ? convertData(searchResults) : []}
      value={searchQuery}
      onChange={({ detail }) => {
        setSearchQuery(detail.value);
      }}
      onLoadItems={({ detail }) => setQueryString(detail.filteringText)}
      onBlur={() => setSearchResults([])}
      onSelect={({ detail }) => {
        setSelectedItem(detail.selectedOption);
        onSelectedItemChanged(detail.selectedOption);
        setSearchQuery(detail.selectedOption.label);
      }}
      statusType={isLoading ? "loading" : "finished"}
      loadingText="Loading"
      placeholder="Search for a title"
      filteringType="manual"
    />
  );
};

export default TitleSelector;