import { Box, Container, Link } from '@cloudscape-design/components';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import CustomLoadingOverlay from '../../../components/PantheonLoading';
import axiosInstance from '../../../utils/axios';

interface OTTWidgetProps {
    title: string;
    payload: object;
    endpoint?: string;
}

const fetchTrendingData = async ({ queryKey }) => {
    const [_key, { endpoint, payload }] = queryKey;
    const { data } = await axiosInstance.request({
        url: endpoint,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: payload
    });
    return data;
};

const OTTWidget: React.FC<OTTWidgetProps> = ({ title, payload, endpoint }) => {
    const [hoveredIndex, setHoveredIndex] = useState(0);
    const { data, error, isLoading } = useQuery(['trending', { endpoint, payload }], fetchTrendingData);

    if (isLoading) {
        return (
            <Container >
                <Box textAlign="center" padding={{ vertical: 'xs' }}>
                    <div className="font-bold text-lg bg-slate-200 dark:bg-slate-800 p-2 text-center">{title}</div>
                </Box>
                <div className="flex justify-center items-center h-48">
                    <CustomLoadingOverlay />
                </div>
            </Container>
        );
    }

    if (error) {
        return <Box textAlign="center">Error fetching data</Box>;
    }

    return (
        <Container disableContentPaddings>
            <div className="font-bold text-lg bg-slate-200 dark:bg-slate-800 p-2 text-center truncate rounded-t-2xl">{title}</div>
            <div className="aspect-w-16 aspect-h-9 mb-2">
                <img
                    src={`https://image.tmdb.org/t/p/w300${data[hoveredIndex].image_url}`}
                    alt={data[hoveredIndex].ip}
                    className="object-cover w-full h-full "
                    loading="lazy"
                />
            </div>
            <ol className="list-decimal list-inside text-sm space-y-1 p-2">
                {data.slice(0, 10).map((item, index) => (
                    <li key={index} className="truncate">
                        <span
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(0)}
                        >
                            <Link
                                variant='secondary'
                                href={`${window.location.origin}/item/${item.ip_id}`}>
                                {item.ip}
                            </Link>
                        </span>
                    </li>
                ))}
            </ol>
        </Container>
    );
};

export default OTTWidget;
