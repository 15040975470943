import {
  SpaceBetween,
} from "@cloudscape-design/components";
import { AgGridReact } from "ag-grid-react";
import React, { useRef, useState } from "react";
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";
import { useGridConfig } from "./hooks/useGridConfig";
import TableOptions from "./TableOptions";
import { convertData } from "../../utils";

const MetricsTable = ({ metrics, titles, metricData, multiParamInfo }) => {
  const { theme } = useGetGridTheme();
  const tableRef = useRef(null);

  const [heatmapEnabled, setHeatmapEnabled] = useState(true);

  const { gridOptions } = useGridConfig({
    metrics,
    titles: convertData(titles),
    metricData,
    multiParamInfo,
    heatmapEnabled,
  });

  return (
    <SpaceBetween direction="vertical" size="s">
      <TableOptions
        tableRef={tableRef}
        metrics={metrics}
        heatmapEnabled={heatmapEnabled}
        setHeatmapEnabled={setHeatmapEnabled}
      />
      <div
        style={{
          height: gridOptions?.rowData?.length > 10 ? "500px" : "auto",
          width: "100%",
        }}
        className={theme}
      >
        <AgGridReact
          ref={tableRef}
          {...gridOptions}
        />
      </div>
    </SpaceBetween>
  );
};

export default MetricsTable;
