import React, { useEffect, useState } from "react";
import { ContentLayout, Header, SpaceBetween, Spinner, Tabs } from "@cloudscape-design/components";
import { navItems, NavItemsWithId } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { useGetGoogleSearchData } from "./api/hooks/useGetGoogleSearchData";
import moment from "moment";
import { CATEGORIES, Category, Categories, COUNTRIES, Country } from "./constants";
import { Parameters } from "./components/Parameters";
import { CountryMap } from "./components/CountryMap/CountryMap";
import { getAllCategories } from "./utils";
import { TimeseriesChart } from "./components/TimeseriesChart/TimeseriesChart";
import { useGetTimelineEvents } from "../../../hooks/TimelineEvents/useGetTimelineEvents";
import { convertData } from "../../../components/datapoint-comparison/utils";

export const GoogleDashboard = () => {
  const title = "Google Search Dashboard";

  const [selectedTitle, setSelectedTitle] = useState<any | null>(null);
  const [countries, setCountries] = useState<Country[]>([COUNTRIES.UNITED_STATES]);
  const [categories, setCategories] = useState<Category[]>(getAllCategories(CATEGORIES));
  const [dateRange, setDateRange] = useState<any>({
    type: "absolute",
    startDate: "2023-01-01",
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [nestedData, setNestedData] = useState<any>({});

  const { data: googleSearchData, isLoading, error } = useGetGoogleSearchData({
    enabled: selectedTitle != null && countries.length > 0 && categories.length > 0,
    ipIds: [selectedTitle?.ip_id],
    geoNames: Object.values(COUNTRIES).map(country => country.value),
    categories: getAllCategories(CATEGORIES).map(category => category.value),
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });

  const { data: timelineEvents, isLoading: timelineEventsLoading, error: timelineEventsError } = useGetTimelineEvents({
    enabled: selectedTitle != null,
    titleIds: [selectedTitle?.ip_id],
  });

  const processGoogleSearchData = (data: any) => {
    const keyInfo = data.key_info;
    const unstructuredData = data.data;

    const nestedData = {};
    keyInfo.forEach((item: any, index: number) => {
      const {
        ip_id: titleId,
        category: categoryValue,
        geo_name: countryValue,
        has_data: hasData,
      } = item;
      if (!hasData) return;
      if (!nestedData[titleId]) {
        nestedData[titleId] = {};
      }
      if (!nestedData[titleId][countryValue]) {
        nestedData[titleId][countryValue] = {};
      }
      if (!nestedData[titleId][countryValue][categoryValue]) {
        nestedData[titleId][countryValue][categoryValue] = unstructuredData.map(dataItem => {
          return {
            date: dataItem.date,
            value: dataItem.data[index],
          };
        });
      }
    });

    setNestedData(nestedData);
  };

  useEffect(() => {
    if (!googleSearchData) return;
    processGoogleSearchData(googleSearchData);
  }, [googleSearchData]);

  useEffect(() => console.log(timelineEvents), [timelineEvents])

  return (
    <Layout
      title={title}
      breadcrumbs={[]}
      navItems={navItems as NavItemsWithId}
      drawers={[]}
      contentType="table"
      content={
        <ContentLayout
          header={
            <>
              <Header
                variant="h2"
              >
                {title}
              </Header>
            </>
          }
        >
          <SpaceBetween direction="vertical" size="m">
            <Parameters
              setSelectedTitle={setSelectedTitle}
            />
            {isLoading ? (
              <div className="h-32 flex items-center justify-center w-full">
                <Spinner size="large" />
              </div>
            ) : googleSearchData != null && !error ? (
              <>
                <TimeseriesChart
                  selectedTitle={selectedTitle ? convertData([selectedTitle])[0] : null}
                  nestedData={nestedData}
                  categoryOptions={getAllCategories(CATEGORIES).filter(cat => googleSearchData?.key_info?.some((keyInfo: any) => keyInfo.category === cat.value && keyInfo.has_data))}
                  countryOptions={Object.values(COUNTRIES)}
                  timelineEvents={timelineEvents}
                />
                <CountryMap
                  nestedData={nestedData}
                  categoryOptions={getAllCategories(CATEGORIES).filter(cat => googleSearchData?.key_info?.some((keyInfo: any) => keyInfo.category === cat.value && keyInfo.has_data))}
                />
              </>
            ) : (
              <div className="h-32 flex items-center justify-center w-full">
                {error ? error.message : "Select a title to get started"}
              </div>
            )}
          </SpaceBetween>
        </ContentLayout>
      }
    />
  );
};