import { ContentLayout, ExpandableSection, Grid, Header, SpaceBetween } from "@cloudscape-design/components";
import React from "react";
import { NavItemsWithId, navItems, } from "../../../layouts/common/menu/side-menu";
import OTTWidget from "../components/OTTWidget";
import TrendingWidget from "../components/TrendingWidget";
import { Layout } from "../Layout";
import { GRPageCards } from "./GRPageCards";

const title = "Overview";
const gridItems = 3;
const gridDefinition = Array.from({ length: gridItems }, () => ({
  colspan: { default: 12, s: 4 },
}));

const fields = "s.ip_id, s.ip, s.daily_rank, s.weekly_rank, s.daily_rank_change, s.weekly_rank_change, s.daily_rank_trend, s.weekly_rank_trend, s.month_score,s.release_date,s.image_url";
const movies_endpoint = "/pql?key=platform/global_movies&file_type=P&sort=daily_rank:asc&size=10"
const series_endpoint = "/pql?key=platform/global_series&file_type=P&sort=daily_rank:asc&size=10"
const gaming_endpoint = "/pql?key=platform/global_gaming&file_type=P&sort=daily_rank:asc&size=10"

const universalPicturesPayload = {
  sql: `SELECT ${fields} FROM s3object s WHERE s.production LIKE '%Universal Pictures%'`
};

const focusFeaturesPayload = {
  sql: `SELECT ${fields} FROM s3object s WHERE s.production LIKE '%Blumhouse Productions%'`
};

const dreamworksPayload = {
  sql: `SELECT ${fields} FROM s3object s WHERE s.production LIKE '%DreamWorks Animation%'`
};

const trendingPayload = {
  sql: `SELECT ${fields} FROM s3object s WHERE s.weekly_rank_change > 100 OR s.daily_rank_change > 100`
};

const ottProviders = [
  { name: "Peacock", key: "Peacock" },
  { name: "Netflix", key: "Netflix" },
  { name: "HBO Max", key: "Max" },
  { name: "Hulu", key: "Hulu" },
  { name: "Amazon Prime", key: "Amazon Prime Video" },
  { name: "Disney+", key: "Disney Plus" },
  { name: "Apple TV+", key: "Apple TV Plus" },
  { name: "Paramount+", key: "Paramount Plus" },
];

const generateOttPayload = (provider) => ({
  sql: `SELECT s.ip,s.ip_id, s.weekly_rank,s.image_url FROM s3object s WHERE s.providers LIKE '%${provider}%'`
});

const generateOttEndpoint = (baseEndpoint) => {
  return baseEndpoint.replace('sort=daily_rank:asc', 'sort=weekly_rank:asc');
};

function GRHomePage() {
  const breadcrumbs = [
    { text: "Ranking" },
    { text: "Global" },
    { text: title },
  ];

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      content={
        <ContentLayout
          header={
            <Header
              variant="h2"
              description="A collection of dashboards that provide insights into all available titles and their performance on various platforms"
            >
              {title}
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="m">
            <ExpandableSection variant="default" defaultExpanded headerText="Trending Titles by Category">
              <Grid gridDefinition={gridDefinition}>
                <TrendingWidget
                  title="Trending Movies"
                  payload={trendingPayload}
                  endpoint={movies_endpoint}
                />
                <TrendingWidget
                  title="Trending Series"
                  payload={trendingPayload}
                  endpoint={series_endpoint}
                />
                <TrendingWidget
                  title="Trending Games"
                  payload={trendingPayload}
                  endpoint={gaming_endpoint}
                />
              </Grid>
            </ExpandableSection>
            <ExpandableSection variant="default" defaultExpanded headerText="Trending Movies by Studios">
              <Grid gridDefinition={gridDefinition}>
                <TrendingWidget
                  title={<span className="line-clamp-1"> Universal Pictures Productions</span>}
                  payload={universalPicturesPayload}
                  endpoint={movies_endpoint}
                />
                <TrendingWidget
                  title={
                    <span className="line-clamp-1">DreamWorks Animation Productions</span>
                  }
                  payload={dreamworksPayload}
                  endpoint={movies_endpoint}
                />
                <TrendingWidget
                  title={<span className="line-clamp-1">Blumhouse Productions</span>}
                  payload={focusFeaturesPayload}
                  endpoint={movies_endpoint}
                />
              </Grid>
            </ExpandableSection>
            <ExpandableSection variant="default" defaultExpanded headerText="Top Movies on U.S. Streaming Services (Last 7 Days)">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xxl:grid-cols-8 gap-2">
                {ottProviders.map((provider) => (
                  <OTTWidget
                    key={provider.key}
                    title={`${provider.name}`}
                    payload={generateOttPayload(provider.key)}
                    endpoint={generateOttEndpoint(movies_endpoint)}
                  />
                ))}
              </div>
            </ExpandableSection>
            <ExpandableSection variant="default" defaultExpanded headerText="Top Series on U.S. Streaming Services (Last 7 Days)">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xxl:grid-cols-8 gap-2">
            {ottProviders.map((provider) => (
                  <OTTWidget
                    key={provider.key}
                    title={`${provider.name}`}
                    payload={generateOttPayload(provider.key)}
                    endpoint={generateOttEndpoint(series_endpoint)}
                  />
                ))}
              </div>
            </ExpandableSection>
            <GRPageCards />
          </SpaceBetween>
        </ContentLayout>
      }
      contentType="dashboard"
    />
  );
}

export default GRHomePage;
