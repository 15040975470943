import React, { useMemo, useState } from "react";
import { getImageUrlBig } from "../../utils";
import { Link } from "@cloudscape-design/components";
import { VerticalIcon } from "../../../VerticalIcon";
import { DEFAULT_DARK_STROKES } from "../../constants";

export const SummaryRanking = ({ metrics, titles, timeseriesData, multiParamInfo }) => {

  const rankingData = useMemo(() => {
    const summaryMetricKeys = metrics
      .filter((metric) => !metric.hideInSummary)
      .map((metric) => metric.key);

    let i = 0;
    const titleColorMap = multiParamInfo.map((paramInfo, paramIndex) => (
      titles.filter(title => paramInfo.title_ids.includes(title.ip_id)).map((title) => (
        { [`${title.value}_${paramIndex}`]: DEFAULT_DARK_STROKES[i++ % DEFAULT_DARK_STROKES.length] }
      )).reduce((acc, obj) => ({ ...acc, ...obj }), {})
    )).reduce((acc, obj) => ({ ...acc, ...obj }), {});

    const titleTotalMetricValues = titles.map((title) => {
      const validMetricKeys = summaryMetricKeys.map((key) => `${title.ip_id}_${key}`);
      const titleValuesSum = timeseriesData.map((data) => {
        const dataValues = Object.keys(data)
          .filter((key) => validMetricKeys.includes(key))
          .map((key) => {
            const metricValue = data[key];
            return metricValue;
          });
        return dataValues.reduce((acc, value) => acc + value, 0);
      });
      const titleTotalValue = titleValuesSum.reduce((acc, value) => acc + value, 0);
      return {
        title: title,
        color: titleColorMap[`${title.ip_id}_0`],
        imageUrl: getImageUrlBig(
          title.vertical,
          title.ip_id,
          title.image_url,
        ),
        totalValue: titleTotalValue,
      };
    }).sort((a, b) => b.totalValue - a.totalValue);

    return titleTotalMetricValues;
  }, [metrics, titles, timeseriesData]);

  return (
    <div className="border dark:border-slate-600 border-slate-400 rounded-lg h-full relative overflow-hidden">
      <div className="flex flex-col overflow-y-scroll w-full h-full absolute top-0 left-0">
        <div>
          {rankingData?.map((data, index) => {
            const percentage = Math.floor(
              (data.totalValue /
                Math.max(...rankingData.map((d) => d.totalValue))) *
              100,
            );
            return (
              <div
                key={index}
                className="border-b dark:border-slate-600 border-slate-400 flex items-center h-17 relative overflow-hidden"
              >
                <div className="w-12 min-w-12 text-center font-bold text-xl">
                  {index + 1}
                </div>
                <div
                  className="h-full bg-opacity-50 max-w-full relative"
                  style={{ width: `${percentage}%`, backgroundColor: `${data.color}bb` }}
                >
                  <div className="absolute w-full h-full flex items-center p-4 font-bold text-nowrap">
                    <div className="dark:bg-sidebarLow/70 bg-white/70 backdrop-blur-md px-2 py-1 rounded-md flex gap-2 items-center">
                      <VerticalIcon vertical={data.title.vertical} />
                      <Link href={`/item/${data.title.ip_id}`}>
                        {data.title.ip}
                      </Link>
                      <div className="text-xs dark:text-slate-400 text-slate-600 font-normal">
                        {data.title.release_date.split("-")[0]}
                      </div>
                    </div>
                  </div>
                  {percentage > 10 && (
                    <img src={data.imageUrl} className="image-cover h-full float-right" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
