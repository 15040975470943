import { Link } from "@cloudscape-design/components";

const RottenTomatesAudienceScore = ({ rtFanSummary }) => {
  if (!rtFanSummary || rtFanSummary.score == null) {
    return (
      <div>
        <div className="pb-1 flex space-x-2 items-center">
          <div className="pl-8 text-xl text-slate-600 font-bold">- %</div>
        </div>
        <div className="text-xs text-slate-600 font-bold">Popcornmeter</div>
      </div>
    );
  }

  const isUpright =
    rtFanSummary.audienceRating === "Upright" ||
    rtFanSummary.rating === "Upright";
  const isSpilled =
    rtFanSummary.audienceRating === "Spilled" ||
    rtFanSummary.rating === "Spilled";
  const count = rtFanSummary.numReviews || rtFanSummary.count;
  const score = rtFanSummary.score;
  const isVerifiedHot = rtFanSummary["verified-hot"] === "true";

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex space-x-2 items-center">
        {isVerifiedHot ? (
          <img
            src="/rticons/aud_score-verifiedhot.svg"
            alt="Verified Hot"
            className="h-12 w-12"
          />
        ) : isUpright ? (
          <img
            src="/rticons/aud_score-fresh.svg"
            alt="Upright"
            className="h-12 w-12"
          />
        ) : isSpilled ? (
          <img
            src="/rticons/aud_score-rotten.svg"
            alt="Spilled"
            className="h-12 w-12"
          />
        ) : null}
        <div className="text-black dark:text-slate-100">
          <div className="text-xl dark:text-slate-50 font-bold leading-[1.25rem]">
            {score ? `${score}%` : "N/A"}
          </div>
          {rtFanSummary.url ? (
            <div className="font-bold">
              <Link
                fontSize="body-s"
                href={rtFanSummary.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Popcornmeter
              </Link>
            </div>
          ) : (
            <div className="text-xs font-bold">Popcornmeter</div>
          )}
          <div className="text-xs italic dark:text-slate-400">
            {parseInt(count, 10).toLocaleString()} ratings
          </div>
        </div>
      </div>
    </div>
  );
};

export default RottenTomatesAudienceScore;
