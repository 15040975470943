import React, { useState } from 'react';
import { useChartConfig } from './hooks/useChartConfig';
import { AgCharts } from 'ag-charts-react';
import { Box, Header, Select, SpaceBetween } from '@cloudscape-design/components';

export const CountryMap = ({ 
  nestedData, 
  categoryOptions,
}) => {

  const [selectedCategory, setSelectedCategory] = useState(categoryOptions[0]);

  const chartOptions = useChartConfig({
    nestedData,
    selectedCategory,
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between"> 
        <Header>Search volume by region</Header>
        <SpaceBetween size="s" direction="horizontal">
          <Select
            options={categoryOptions}
            selectedOption={selectedCategory}
            onChange={({ detail }) => setSelectedCategory(detail.selectedOption)}
          />
        </SpaceBetween>
      </div>
      <div style={{ width: "100%", height: "500px" }}>
        <AgCharts
          options={chartOptions as any}
        />
      </div>
    </div>
  );
};