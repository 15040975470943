import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { SpaceBetween, Grid, FormField, Multiselect, ButtonDropdown, Link, Header, TokenGroup, Spinner, Button } from "@cloudscape-design/components";
import { RangeDateSelector } from "../../../RangeDateSelector";
import MultiTitleSelector from "./MultiTitleSelector";
import AlignDateRangeModal from "./AlignDateRangeModal";
import { DEFAULT_DATE_RANGE } from "../../constants";

const Parameters = ({
  metrics,
  options,
  setExternallyLoadedTitles,
  dateRanges,
  setDateRanges,
  selectedMetrics,
  setSelectedMetrics,
  selectedTitles,
  setSelectedTitles,
  activeMetricKey,
  setActiveMetricKey,
  onTitlesLoadingChanged,
}) => {

  const [ alignDateRangeModalOpen, setAlignDateRangeModalOpen ] = useState(false);
  const [ editingDateRange, setEditingDateRange ] = useState(null);
  const [ savedAlignDateRangeParams, setSavedAlignDateRangeParams ] = useState([]);
  const [ titlesLoading, setTitlesLoading ] = useState(false);

  const metricOptions = useMemo(() => metrics?.map(metric => (
    {
      label: metric.name,
      value: metric.key,
      description: metric.datapoints.map(datapoint => `${datapoint.platform.name} ${datapoint.datapoint.name}`).join(", "),
      group: metric.group,
    }
  )) ?? [], [metrics]);

  const metricGroups = useMemo(() => {
    const x = metrics.map(metric => metric.group).filter((group, index, self) => self.indexOf(group) === index);
    return x;
  }, [metrics]);

  return (
    <div>
      {options.variant === "full" ? (
        <SpaceBetween direction="vertical" size="s">
          {[...Array(dateRanges.length).keys()].map(paramIndex => (
            <Grid
              key={paramIndex}
              gridDefinition={[
                { colspan: { default: 4, s: 6 } },
                { colspan: { default: 4, s: 3 } },
                { colspan: { default: 4, s: 3 } },
              ]}
            >
              <FormField
                label={paramIndex == 0 ? "Titles" : null}
                stretch
              >
                <MultiTitleSelector
                  selectedTitles={selectedTitles[paramIndex]}
                  setSelectedTitles={(titles) => {
                    setSelectedTitles(selectedTitles.map((t, i) => i === paramIndex ? titles : t));
                  }}
                  onSearchResultsChanged={(results) => {
                    setExternallyLoadedTitles(results);
                  }}
                  shortenPlaceholder={false}
                  onLoadingChanged={(loading) => {
                    setTitlesLoading(loading);
                    if (onTitlesLoadingChanged) {
                      onTitlesLoadingChanged(loading);
                    }
                  }}
                />
              </FormField>
              {paramIndex === 0 ? (
                <FormField
                  label="Metrics"
                >
                  <Multiselect
                    selectedOptions={metricOptions.filter(option => selectedMetrics.map(m => m.key).includes(option.value))}
                    onChange={({ detail }) => {
                      setSelectedMetrics(metrics.filter(metric => detail.selectedOptions.map(m => m.value).includes(metric.key)));
                      if (!activeMetricKey) {
                        setActiveMetricKey(detail.selectedOptions[0].value);
                      } else if (!detail.selectedOptions.map(option => option.value).includes(activeMetricKey)) {
                        if (detail.selectedOptions.length > 0) {
                          setActiveMetricKey(detail.selectedOptions[0].value);
                        } else {
                          setActiveMetricKey(null);
                        }
                      }
                    }}
                    options={metricGroups.map(group => (
                      group === undefined ? (
                        { label: "Other", options: metricOptions.filter(option => option.group == null) }
                      ) : (
                        { label: metricGroups.find(g => g?.key === group.key).name, options: metricOptions.filter(option => option.group && option.group.key === group.key) }
                      )
                    ))}
                    placeholder={selectedMetrics.length > 0 ? `${selectedMetrics.length} ${selectedMetrics.length == 1 ? "metric" : "metrics"} selected` : "Select metrics to compare"}
                    hideTokens={true}
                  />
                </FormField>
              ) : (
                <div></div>
              )}
              <FormField
                label={paramIndex == 0 ? "Date range" : null}
              >
                <div className="flex w-full">
                  <div className="grow">
                    <RangeDateSelector
                      defaults={dateRanges[paramIndex]}
                      onChange={(e) => {
                        e.startDate = moment.utc(e.startDate).format("YYYY-MM-DD");
                        e.endDate = moment.utc(e.endDate).format("YYYY-MM-DD");
                        setDateRanges(dateRanges.map((range, index) => index === paramIndex ? e : range));
                      }}
                    />
                  </div>
                  <ButtonDropdown
                    variant="icon"
                    expandToViewport
                    items={[
                      {
                        text: "Align to event",
                        id: "align-to-event",
                      },
                      {
                        text: "Remove",
                        id: "remove",
                        disabled: paramIndex === 0,
                      },
                    ]}
                    onItemClick={({ detail }) => {
                      switch (detail.id) {
                        case "align-to-event":
                          setEditingDateRange({ index: paramIndex, dateRange: dateRanges[paramIndex] });
                          setAlignDateRangeModalOpen(true);
                          break;
                        case "remove":
                          setDateRanges(dateRanges.filter((range, index) => index !== paramIndex));
                          setSelectedTitles(selectedTitles.filter((titles, index) => index !== paramIndex));
                          break;
                      }
                    }}
                  />
                </div>
              </FormField>
            </Grid>
          ))}
          <Link
            variant="primary"
            onFollow={() => {
              setDateRanges([...dateRanges, DEFAULT_DATE_RANGE]);
              setSelectedTitles([...selectedTitles, []]);
            }}
          >
            (+) Add date range
          </Link>
        </SpaceBetween>
      ) : options.variant === "readonly" ? (
        <SpaceBetween direction="vertical" size="s">
          <Header
            variant="h3"
          >
            {[...Array(dateRanges.length).keys()].map(paramIndex => (
              <div>
                <div>
                  {selectedTitles[paramIndex].map(title => title.ip).join(", ")}{" "}
                  <span className="font-normal">({moment(dateRanges[paramIndex].startDate).format("l")} - {moment(dateRanges[paramIndex].endDate).format("l")})</span>
                </div>
              </div>
            ))}
          </Header>
        </SpaceBetween>
      ) : options.variant === "tokens" ? (
        <>
          <div className="flex justify-between items-start">
            <FormField label="Titles" stretch>
              {selectedTitles.flat().length > 0 ? (
                <div className="flex flex-col gap-2">
                  <TokenGroup 
                    disableOuterPadding
                    items={selectedTitles.map(titles => titles.map(title => ({ label: title.ip ?? "Loading..." }))).flat()}
                    onDismiss={({ detail: { itemIndex }}) => {
                      setSelectedTitles(selectedTitles.map((titles, index) => index === Math.floor(itemIndex / titles.length) ? titles.filter((title, i) => i !== itemIndex % titles.length) : titles));
                    }}
                  />
                  <Button variant="inline-link" iconName="remove" onClick={() => setSelectedTitles(dateRanges.map(x => []))} loading={titlesLoading}>Clear selected titles</Button>
                </div>
              ) : (
                <div className="text-slate-400">No titles selected</div>
              )}
            </FormField>
            {options.showDateRanges && (
              <FormField label="Date range">
                <RangeDateSelector
                  defaults={dateRanges[0]}
                  onChange={(e) => {
                    e.startDate = moment.utc(e.startDate).format("YYYY-MM-DD");
                    e.endDate = moment.utc(e.endDate).format("YYYY-MM-DD");
                    setDateRanges(dateRanges.map((range, index) => index === 0 ? e : range));
                  }}
                />
              </FormField>
            )}
          </div>
        </>
      ) : null}
      <AlignDateRangeModal
        visible={alignDateRangeModalOpen}
        setVisible={setAlignDateRangeModalOpen}
        dateRange={editingDateRange?.dateRange}
        setDateRange={(newDateRange) => {
          setEditingDateRange(prevRange => ({ ...prevRange, dateRange: newDateRange }));
        }}
        onSubmit={({ detail }) => {
          setDateRanges(dateRanges.map((range, index) => editingDateRange.index === index ? detail.dateRange : range));
          setSavedAlignDateRangeParams(prevParams => {
            const newParams = [...prevParams];
            newParams[editingDateRange.index] = detail;
            return newParams;
          });
          setAlignDateRangeModalOpen(false);
        }}
        defaultSelectedTitle={selectedTitles[editingDateRange?.index]?.length > 0 ? selectedTitles[editingDateRange?.index][0] : null}
        defaults={savedAlignDateRangeParams[editingDateRange?.index]}
      />
    </div>
  );
};

export default Parameters;