import { PropertyFilterProps } from "@cloudscape-design/components";
import { FILTER_JOIN_OPERATIONS, FILTER_OPERATIONS, FILTER_TYPES } from "../../config-global";

export const getFilteredTokens = (tokens: Array<PropertyFilterProps.Token>) => {
  const include = [];
  const exclude = [];

  const checkForDateString = (str: string) =>
    /^\d{4}-\d{2}-\d{2}$/.test(str) ? new Date(str).getTime() / 1000 : str;

  const formatFilter = (token: PropertyFilterProps.Token) => {
    switch (token.operator) {
      case "=":
        include.push({
          match: {
            [token.propertyKey]: {
              query: token.value,
              auto_generate_synonyms_phrase_query: false,
            },
          },
        });
        break;
      case ":":
        include.push({
          query_string: {
            default_field: token.propertyKey,
            query: `"*${token.value}*"`,
          },
        });
        break;
      case "!=":
        exclude.push({
          query_string: {
            default_field: token.propertyKey,
            query: `*${token.value}*`,
          },
        });
        break;
      case ">=":
        include.push({
          range: {
            [token.propertyKey]: {
              gte: checkForDateString(token.value),
            },
          },
        });
        break;
      case "<=":
        include.push({
          range: {
            [token.propertyKey]: {
              lte: checkForDateString(token.value),
            },
          },
        });
        break;
      case ">":
        include.push({
          range: {
            [token.propertyKey]: {
              gt: checkForDateString(token.value),
            },
          },
        });
        break;
      case "<":
        include.push({
          range: {
            [token.propertyKey]: {
              lt: checkForDateString(token.value),
            },
          },
        });
        break;
      case "blank":
        exclude.push({
          exists: {
            field: token.propertyKey,
          },
        });
        break;
      case "notBlank":
        include.push({
          exists: {
            field: token.propertyKey,
          },
        });
        break;
      default:
        return null;
    }
  };

  tokens.forEach(formatFilter);

  return { include, exclude };
};

export const getFilterConditionsFromFilters = (filters) => {
  const conditions = [];

  if (!filters || filters.length === 0) {
    return conditions;
  }

  filters.filter(filter => filter.value).forEach(filter => {
    if (filter.value.length === 1) {
      conditions.push({
        filterType: filter.filterType,
        colId: filter.key,
        type: filter.filterOperation,
        filter: filter.value[0],
      });
    } else if (filter.value.length > 1) {
      conditions.push({
        filterType: "join",
        type: filter.valueJoinOperation,
        conditions: filter.value.map(value => ({
          filterType: filter.filterType,
          colId: filter.key,
          type: filter.filterOperation,
          filter: value,
        })),
      });
    }
  });

  return conditions;
};

export const getFiltersFromFilterConditions = (filterConditions) => {
  const filters = [];

  if (!filterConditions || filterConditions.length === 0) {
    return filters;
  }

  filterConditions.forEach(filterCondition => {
    if (filterCondition.filterType === "join") {
      filters.push({
        key: filterCondition.conditions[0].colId,
        value: filterCondition.conditions.map(condition => condition.filter),
        filterType: filterCondition.conditions[0].filterType,
        filterOperation: filterCondition.conditions[0].type,
        valueJoinOperation: filterCondition.type,
      });
    } else {
      filters.push({
        key: filterCondition.colId,
        value: [filterCondition.filter],
        filterType: filterCondition.filterType,
        filterOperation: filterCondition.type,
      });
    }
  });

  return filters;
};

const SQL_FILTER_OPERATIONS = {
  [FILTER_OPERATIONS.EQUALS]: "=",
  [FILTER_OPERATIONS.NOT_EQUAL]: "<>",
  [FILTER_OPERATIONS.LESS_THAN]: "<",
  [FILTER_OPERATIONS.LESS_THAN_OR_EQUAL]: "<=",
  [FILTER_OPERATIONS.GREATER_THAN]: ">",
  [FILTER_OPERATIONS.GREATER_THAN_OR_EQUAL]: ">=",
  [FILTER_OPERATIONS.CONTAINS]: "LIKE",
  [FILTER_OPERATIONS.NOT_CONTAINS]: "NOT LIKE",
  [FILTER_OPERATIONS.STARTS_WITH]: "LIKE",
  [FILTER_OPERATIONS.ENDS_WITH]: "LIKE",
  [FILTER_OPERATIONS.BLANK]: "IS NULL OR ''",
  [FILTER_OPERATIONS.NOT_BLANK]: "IS NOT NULL AND ''",
  [FILTER_OPERATIONS.TRUE]: "= TRUE",
  [FILTER_OPERATIONS.FALSE]: "= FALSE",
};

export const filterObjectToSqlQuery = (filterObject, columnPrefix="") => {
  if (!filterObject || !filterObject.filterType) {
    throw new Error("Can't convert ag-grid filter to SQL: invalid filter object");
  }

  const colId = columnPrefix ? `${columnPrefix}.${filterObject.colId}` : filterObject.colId;

  switch (filterObject.filterType) {
    case FILTER_TYPES.JOIN:
      return filterObject.conditions.map(condition => `(${filterObjectToSqlQuery(condition)})`).join(` ${filterObject.type} `);
    case FILTER_TYPES.NUMBER:
      return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]} ${filterObject.filter}`;
    case FILTER_TYPES.TEXT:
      let filterValue = filterObject.filter;
      if (filterObject.type === FILTER_OPERATIONS.STARTS_WITH) {
        filterValue = `${filterObject.filter}%`;
      } else if (filterObject.type === FILTER_OPERATIONS.ENDS_WITH) {
        filterValue = `%${filterObject.filter}`;
      } else if (filterObject.type === FILTER_OPERATIONS.CONTAINS) {
        filterValue = `%${filterObject.filter}%`;
      } else if (filterObject.type === FILTER_OPERATIONS.NOT_CONTAINS) {
        filterValue = `%${filterObject.filter}%`;
      }
      return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]} '${filterValue}'`;
    case FILTER_TYPES.BOOLEAN:
      return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]}`;
    case FILTER_TYPES.OBJECT: // The values of this are an array converted to a comma separated string
      const values = filterObject.filter.replaceAll("\\,", "\\|").split(",").map(value => value.replaceAll("\\|", "\\\\,"));
      if (filterObject.type === FILTER_OPERATIONS.CONTAINS) {
        return values.map(value => `${colId} LIKE '%${value}%'`).join(" AND ");
      } else if (filterObject.type === FILTER_OPERATIONS.NOT_CONTAINS) {
        return values.map(value => `${colId} NOT LIKE '%${value}%'`).join(" AND ");
      } else {
        return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]} ('${values.join("','")}')`;
      }
    case FILTER_TYPES.DATE:
      return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]} '${filterObject.filter}'`;
    case FILTER_TYPES.DATE_STRING:
      return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]} '${filterObject.filter}'`;
  }
};
