import {
  Box,
  Container,
  ExpandableSection,
  Grid,
  SpaceBetween,
} from "@cloudscape-design/components";
import "ag-charts-enterprise";
import { AgCharts } from "ag-charts-react";
import React, { useEffect, useState } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../../utils/shortenInteger";
import RottenTomatesAudienceScore from "../../components/Scores/RTAudienceScore";
import RottenTomatesCriticScore from "../../components/Scores/RTCriticScore";

function ImdbPlot({ itemData }) {
  const { theme } = useGetChartTheme(CHART_TYPES.VIVID);
  const [options, setOptions] = useState({});

  useEffect(() => {
    const episodes = itemData.flatMap((season) =>
      season.episodes.map((episode) => ({
        ...episode,
        season: season.season_number,
      })),
    );

    const seasonAverages = Array.from(
      episodes.reduce((acc, d) => {
        if (d.imdb_vote_average != null && d.imdb_vote_count != null) {
          if (!acc.has(d.season)) {
            acc.set(d.season, { sumRatings: 0, voteCount: 0, episodeCount: 0 });
          }
          const seasonInfo = acc.get(d.season);
          seasonInfo.sumRatings += d.imdb_vote_average;
          seasonInfo.voteCount += d.imdb_vote_count;
          seasonInfo.episodeCount++;
          acc.set(d.season, seasonInfo);
        }
        return acc;
      }, new Map()),
    )
      .map(([season, { sumRatings, voteCount, episodeCount }]) => ({
        season,
        imdb_vote_average: episodeCount > 0 ? sumRatings / episodeCount : null,
        voteCount,
      }))
      .sort((a, b) => a.season - b.season); // Sort the season averages by season

    const chartData = seasonAverages.map(
      ({ season, imdb_vote_average, voteCount }) => ({
        season: `${season}`,
        category: "IMDb Rating",
        rating: imdb_vote_average,
        voteCount: shortenInteger(voteCount),
      }),
    );

    setOptions({
      theme: theme,
      data: chartData,
      series: [
        {
          type: "heatmap",
          xKey: "season",
          xName: "Season",
          yKey: "category",
          yName: "Category",
          colorKey: "rating",
          colorName: "Rating",
          label: {
            color: "black",
            formatter: ({ datum }) =>
              datum.rating.toFixed(1) ? `${datum.rating.toFixed(1)}` : "N/A",
          },
          tooltip: {
            renderer: (params) => {
              const { datum } = params;
              return {
                title: `<span style="color: black; font-weight: bold">Season ${datum.season}</span>`,
                content: `<b>IMDb Rating</b>: ${datum.rating.toFixed(1)}
                               <p><b> Vote Count</b>: ${datum.voteCount}</p>`,
              };
            },
          },
        },
      ],
      gradientLegend: {
        gradient: {
          thickness: 6,
          preferredLength: 400,
        },
        spacing: 25,
        position: "right",
      },
      axes: [
        {
          type: "category",
          position: "bottom",
          label: {
            enabled: true,
          },
          title: {
            text: "Season →",
          },
        },
        {
          type: "category",
          position: "left",
          label: {
            enabled: false,
          },
        },
      ],
    });
  }, [itemData]);

  return <AgCharts options={options} style={{ height: "100%" }} />;
}

const SeasonsGrid = ({ itemData }) => {
  const sortedSeasons = [...itemData].sort(
    (a, b) => a.season_number - b.season_number,
  );
  const gridDefinitions = itemData.map(() => ({
    colspan: { xxs: 12, s: 6, l: 4, xl: 3 },
  }));

  return (
    <SpaceBetween direction="vertical" size="m">
      <ExpandableSection
        variant="container"
        defaultExpanded
        headerText="IMDb Ratings"
      >
        <div className="h-36">
          <ImdbPlot itemData={itemData} />
        </div>
      </ExpandableSection>

      <Grid gridDefinition={gridDefinitions}>
        {sortedSeasons.map((season, index) => {
          const truncatedSummary =
            season.overview.length > 130
              ? season.overview.substring(0, 130) + "..."
              : season.overview;
          return (
            <Container
              fitHeight
              key={index}
              media={{
                content: season.poster_path ? (
                  <img
                    src={`https://image.tmdb.org/t/p/w154${season.poster_path}`}
                    alt={`${season.series_name} - Season ${season.season_number}`}
                  />
                ) : (
                  <div
                    style={{
                      width: "154px",
                      height: "238px",
                      backgroundColor: "gray",
                    }}
                  ></div>
                ),
                height: 150,
                position: "side",
              }}
              footer={
                <div className="flex justify-center gap-4">
                  {season.rtCriticSummary && (
                    <RottenTomatesCriticScore
                      rtCriticSummary={season.rtCriticSummary}
                    />
                  )}
                  {season.rtFanSummary && (
                    <RottenTomatesAudienceScore
                      rtFanSummary={season.rtFanSummary}
                    />
                  )}
                </div>
              }
            >
              <div className="text-slate-500 text-xs">
                {new Date(season.air_date).toLocaleDateString()}
              </div>
              <div>
                <span className="text-lg font-bold">{`Season ${season.season_number}`}</span>
                <span className="text-sm text-slate-500">{` (Episodes: ${season.episode_count})`}</span>
              </div>
              <Box>{truncatedSummary}</Box>
            </Container>
          );
        })}
      </Grid>
    </SpaceBetween>
  );
};

export default SeasonsGrid;
