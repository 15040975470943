export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || "";
export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const IP_LIST = [
  { label: "Pantheon", value: "Pantheon" },
  { label: "NBCU", value: "nbcu" },
];

export const TAGS_LIST = [
  { label: "Wikipedia", value: "wikipedia" },
  { label: "Google Trends", value: "trends" },
  { label: "YouTube", value: "youtube" },
  { label: "YouTube Channel", value: "youtubeChannel" },
  { label: "AO3", value: "a03" },
  { label: "Instagram", value: "instagram" },
  { label: "Wattpad", value: "wattpad" },
  { label: "Reddit", value: "subreddit" },
  { label: "Parent Company", value: "parentCompany" },
  { label: "Twitter", value: "twitter" },
  { label: "TikTok", value: "tiktok" },
  { label: "Fandom", value: "fandom" },
  { label: "TMS ID", value: "tmsId" },
  { label: "EMS ID", value: "emsId" },
  { label: "Rotten Tomatoes", value: "rtUrl" },
  { label: "Twitch", value: "twitch" },
  { label: "Discord", value: "discord" },
  { label: "Steam", value: "steam" },
  { label: "Wikidata ID", value: "wikidata_id" },
  { label: "IMDb ID", value: "imdb_id" },
  { label: "Custom Tags", value: "cTags" },
];

export const COMMON_BOX_OFFICE_REGIONS = {
  XDOM: "Domestic",
  XWW: "WorldWide",
  XNDOM: "International",
  ES: "Spain",
  GB: "United Kingdom",
  AU: "Australia",
  DE: "Germany",
  IT: "Italy",
  FR: "France",
  MX: "Mexico",
  KR: "South Korea",
  BR: "Brazil",
  JP: "Japan",
  TH: "Thailand",
  IN: "India",
  CA: "Canada",
};

export const regionGroups = {
  Regions: ["XDOM", "XWW", "XNDOM"],
  Countries: [
    "ES",
    "GB",
    "AU",
    "DE",
    "IT",
    "FR",
    "MX",
    "KR",
    "BR",
    "JP",
    "TH",
    "IN",
    "CA",
  ],
};

export const formattedBoxOfficeRegions = Object.entries(regionGroups).map(
  ([groupLabel, regionKeys]) => {
    const options = regionKeys.map((key) => ({
      label: COMMON_BOX_OFFICE_REGIONS[key],
      value: key,
      tags: key === "XDOM" ? ["US & Canada"] : [],
    }));
    return { label: groupLabel, options };
  },
);

export const occassion = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Weekend", value: "weekend" },
];

export const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };

export const TYPES = {
  PERSONAL: "personal",
};

export const SERVICES = {
  PLATFORMS: "platforms",
  FAVORITES: "favorites",
  RANKING: "ranking",
  EXPLORE: "explore",
  DASHBOARDS: "dashboards",
  TOOLS: "tools",
};

export const PLATFORMS = {
  WIKIPEDIA: {
    name: "Wikipedia",
    key: "wikipedia",
  },
  TWITTER: {
    name: "Twitter",
    key: "twitter",
  },
  GOOGLE_TRENDS: {
    name: "Google Trends",
    key: "googletrends",
  },
  REDDIT: {
    name: "Reddit",
    key: "reddit",
  },
  AO3: {
    name: "AO3",
    key: "ao3",
  },
  FANDOM: {
    name: "Fandom",
    key: "fandom",
  },
  WATTPAD: {
    name: "Wattpad",
    key: "wattpad",
  },
  YOUTUBE: {
    name: "YouTube",
    key: "youtube",
  },
  STEAM: {
    name: "Steam",
    key: "steam",
  },
  TWITCH: {
    name: "Twitch",
    key: "twitch",
  },
  PIRACY: {
    name: "Piracy",
    key: "piracy",
  },
  ROTTEN_TOMATOES: {
    name: "Rotten Tomatoes",
    key: "rottentomatoes",
  },
  IMDB: {
    name: "IMDb",
    key: "imdb",
  },
  PANTHEON_GLOBAL: {
    name: "Global",
    key: "pantheon_global",
  },
  GLOBAL_WIKIPEDIA: {
    name: "Wikipedia (Global)",
    key: "global_wikipedia",
  },
  GLOBAL_STEAM: {
    name: "Steam (Global)",
    key: "global_steam",
  },
  GLOBAL_TWITCH: {
    name: "Twitch (Global)",
    key: "global_twitch",
  },
  GLOBAL_IMDB: {
    name: "IMDb (Global)",
    key: "global_imdb",
  },
  GLOBAL_ROTTEN_TOMATOES: {
    name: "Rotten Tomatoes (Global)",
    key: "global_rottentomatoes",
  },
  GLOBAL_YOUTUBE: {
    name: "YouTube (Global)",
    key: "global_youtube",
  },
};

export const DATAPOINTS = {
  WIKIPEDIA: {
    PAGE_VIEWS: {
      name: "Page Views",
      key: "page_views", 
    },
    RANK: {
      name: "Rank",
      key: "rank", 
    },
    ZSCORE: {
      name: "Z-Score",
      key: "wikipedia",
    },
  },
  GOOGLE_TRENDS: {
    WORLDWIDE: {
      name: "Worldwide",
      key: "score", 
    },
  },
  TWITTER: {
    TWEET_COUNT: {
      name: "Tweets",
      key: "tweets",
    },
  },
  REDDIT: {
    UPVOTES: {
      name: "Upvotes",
      key: "upvotes",
    },
    COMMENTS: {
      name: "Comments",
      key: "comments",
    },
    SUBREDDIT_MEMBERS: {
      name: "Members",
      key: "subscribers",
    },
  },
  STEAM: {
    PLAYER_COUNT: {
      name: "Players",
      key: "player_count",
    },
    RANK: {
      name: "Rank",
      key: "rank", 
    },
    ZSCORE: {
      name: "Z-Score",
      key: "steam",
    },
  },
  TWITCH: {
    VIEWER_COUNT: {
      name: "Viewers",
      key: "viewers",
    },
    STREAMER_COUNT: {
      name: "Streamers",
      key: "broadcasters",
    },
    RANK: {
      name: "Rank",
      key: "rank", 
    },
    ZSCORE: {
      name: "Z-Score",
      key: "twitch",
    },
  },
  YOUTUBE: {
    VIEWS: {
      name: "Views",
      key: "views",
    },
    COMMENTS: {
      name: "Comments",
      key: "comments",
    },
    LIKES: {
      name: "Likes",
      key: "likes",
    },
    RANK: {
      name: "Rank",
      key: "rank", 
    },
    ZSCORE: {
      name: "Z-Score",
      key: "youtube",
    },
  },
  WATTPAD: {
    VIEWS: {
      name: "Views",
      key: "views",
    },
    COMMENTS: {
      name: "Comments",
      key: "comments",
    },
    STARS: {
      name: "Stars",
      key: "stars",
    },
  },
  AO3: {
    HITS: {
      name: "Hits",
      key: "hits",
    },
    COMMENTS: {
      name: "Comments",
      key: "comments",
    },
    KUDOS: {
      name: "Kudos",
      key: "kudos",
    },
  },
  PIRACY: {
    DOWNLOADS: {
      name: "Downloads",
      key: "downloads",
    },
    ZSCORE: {
      name: "Z-Score",
      key: "piracy",
    },
  },
  IMDB: {
    VOTES: {
      name: "Votes",
      key: "votes",
    },
    RATING: {
      name: "Rating",
      key: "rating",
    },
    RANK: {
      name: "Rank",
      key: "rank", 
    },
    ZSCORE: {
      name: "Z-Score",
      key: "imdb",
    },
  },
  ROTTEN_TOMATOES: {
    AUDIENCE_VOTES: {
      name: "Audience Votes",
      key: "audience_votes",
    },
    AUDIENCE_RATING: {
      name: "Audience Rating",
      key: "audience_rating",
    },
    CRITIC_VOTES: {
      name: "Critic Votes",
      key: "critic_votes",
    },
    CRITIC_RATING: {
      name: "Critic Rating",
      key: "critic_votes",
    },
    RANK: {
      name: "Rank",
      key: "rank", 
    },
    ZSCORE: {
      name: "Z-Score",
      key: "rotten_tomatoes",
    },
  },
};

export const VERTICALS = {
  MOVIES: "Movies",
  SERIES: "Television",
  GAMES: "Gaming",
};

export const FILTER_TYPES = {
  OBJECT: "object",
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  DATE_STRING: "dateString",
  BOOLEAN: "boolean",
  JOIN: "join",
};

export const FILTER_OPERATIONS = {
  CONTAINS: "contains",
  NOT_CONTAINS: "notContains",
  EQUALS: "equals",
  NOT_EQUAL: "notEqual",
  STARTS_WITH: "startsWith",
  ENDS_WITH: "endsWith",
  BLANK: "blank",
  NOT_BLANK: "notBlank",
  GREATER_THAN: "greaterThan",
  GREATER_THAN_OR_EQUAL: "greaterThanOrEqual",
  LESS_THAN: "lessThan",
  LESS_THAN_OR_EQUAL: "lessThanOrEqual",
  TRUE: "true",
  FALSE: "false",
};

export const FILTER_JOIN_OPERATIONS = {
  AND: "AND",
  OR: "OR",
};