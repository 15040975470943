import React, { FC, useEffect, useRef, useState } from 'react';
import { useChartConfig } from './hooks/useChartConfig';
import { AgCharts } from 'ag-charts-react';
import { Link, Multiselect, Select, SpaceBetween } from '@cloudscape-design/components';
import { CATEGORIES, Category, COUNTRIES, Country } from '../../constants';
import { getAllCategories } from '../../utils';
import CrosslineNotes from '../../../../../components/datapoint-comparison/components/TimeseriesChart/CrosslineNotes';

const chartTypeOptions = [
  { value: "line", label: "Line" },
  { value: "area", label: "Distribution" },
];

const granularityOptions = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

interface TimeseriesChartProps {
  selectedTitle: any;
  nestedData: any;
  categoryOptions: Category[];
  countryOptions: Country[];
  timelineEvents: any,
}

export const TimeseriesChart: FC<TimeseriesChartProps> = ({
  selectedTitle,
  nestedData, 
  categoryOptions,
  countryOptions,
  timelineEvents,
}) => {

  const chartRef = useRef(null);

  const [selectedCountries, setSelectedCountries] = useState<Country[]>([COUNTRIES.UNITED_STATES]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>(getAllCategories(CATEGORIES));
  const [selectedChartType, setSelectedChartType] = useState(chartTypeOptions[0]);
  const [selectedGranularity, setSelectedGranularity] = useState(granularityOptions[0]);

  const chartOptions = useChartConfig({
    nestedData,
    selectedCategories,
    selectedCountries,
    selectedChartType,
    selectedGranularity,
    timelineEvents,
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          {selectedTitle && (
            <>
              <img src={selectedTitle?.iconUrl} alt="logo" className="h-12 rounded-md shadow-md" />
              <div className="flex flex-col">
                <Link href={`/item/${selectedTitle.value}`} external><b>{selectedTitle.label}</b></Link>
                <div className="text-xs dark:text-slate-400 text-slate-600">{selectedTitle.tags.join(" \u2022 ")}</div>
              </div>
            </>
          )}
        </div>
        <SpaceBetween size="s" direction="horizontal">
          <Select
            options={granularityOptions}
            selectedOption={selectedGranularity}
            onChange={({ detail }) => setSelectedGranularity(detail.selectedOption as any)}
          />
          <Select
            options={chartTypeOptions}
            selectedOption={selectedChartType}
            onChange={({ detail }) => setSelectedChartType(detail.selectedOption as any)}
          />
          <Multiselect
            options={categoryOptions}
            selectedOptions={selectedCategories}
            onChange={({ detail }) => setSelectedCategories(detail.selectedOptions as any)}
            hideTokens
            placeholder="Select categories"
          />
          <Multiselect
            options={countryOptions}
            selectedOptions={selectedCountries}
            onChange={({ detail }) => setSelectedCountries(detail.selectedOptions as any)}
            hideTokens
            placeholder="Select regions"
          />
        </SpaceBetween>
      </div>
      <div style={{ width: "100%", height: "500px" }} className="relative">
        <AgCharts
          ref={chartRef}
          options={chartOptions as any}
        />
        <CrosslineNotes
          chartRef={chartRef}
          crosslines={chartOptions?.crosslines}
        />
      </div>
    </div>
  );
};