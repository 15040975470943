import { useMemo } from "react";
import topology from "../data/countries3_noata.json";
import { useGetChartTheme } from "../../../../../../hooks/UseTheme/useGetChartTheme";
import { COUNTRIES } from "../../../constants";

export const useChartConfig = ({
  nestedData,
  selectedCategory,
}) => {

  const { theme } = useGetChartTheme();

  const data = useMemo(() => {
    if (!nestedData || Object.keys(nestedData).length === 0) return [];

    const titleId = Object.keys(nestedData)[0];
    const titleData = nestedData[titleId];

    const data = Object.keys(titleData).filter(countryCode => titleData[countryCode][selectedCategory.value] != null).map(countryCode => {
      const categoryData = titleData[countryCode][selectedCategory.value];
      const valuesSum = categoryData.map(d => d.value).reduce((acc: number, val: number) => acc + val, 0);
      return {
        countryCode: countryCode.toUpperCase(),
        country: Object.values(COUNTRIES).find(country => country.value === countryCode),
        value: valuesSum,
      };
    });

    return data;
  }, [nestedData, selectedCategory]);

  const series = useMemo(() => (
    [
      { 
        type: "map-shape-background",
      },
      {
        type: "map-shape",
        idKey: "countryCode",
        topologyIdKey: "ISO_A2",
        colorKey: "value",
        colorName: "Search volume",
        colorRange: ["#ffffff", "#60a5fa"],
        tooltip: {
          renderer: (params) => {
            return {
              title: params.datum.country.label,
              content: `Search volume: ${params.datum.value.toFixed(2)}`,
              backgroundColor: "#60a5fa",
            };
          },
        },
      },
    ]
  ), []);

  const gradientLegend = useMemo(() => (
    {
      enabled: true,
      position: "right",
      gradient: {
        preferredLength: 200,
        thickness: 2,
      },
      scale: {
        label: {
          fontSize: 10,
          fontFamily: "Open Sans",
          formatter: (p) => p.value.toString(),
        },
      },
    }
  ), [nestedData, selectedCategory]);

  return {
    data,
    theme,
    topology,
    series,
    gradientLegend,
    height: 500,
  };
};