import { AgCharts } from "ag-charts-react";
import React, { useContext, useState } from "react";
import { CHART_TYPES, useGetChartTheme } from "../../../../hooks/UseTheme/useGetChartTheme";
import { CompareContext } from "../../DatapointComparison";
import { useChartConfig } from "./hooks/useChartConfig";

const RadialChart = ({
  metrics,
  titles,
  timeseriesData,
  multiParamInfo,
}) => {
  const options = useContext(CompareContext);

  const [ titleMetricInfo, setTitleMetricInfo ] = useState([]);
  const [ chartBaseTheme, setChartBaseTheme ] = useState(null);

  const { chartOptions } = useChartConfig({
    metrics: metrics,
    titles: titles,
    chartHeight: options.chart.height,
    multiParamInfo: multiParamInfo,
    timeseriesData: timeseriesData,
    titleMetricInfo: titleMetricInfo,
    setTitleMetricInfo: setTitleMetricInfo,
    chartBaseTheme,
  });

  return (
    <div 
      style={{ height: `${options.chart.height}px`, width: "100%", position: "relative" }} 
      className="border dark:border-slate-600 border-slate-400 rounded-lg h-full relative overflow-hidden" 
    >
      <AgCharts
        options={chartOptions}
      />
    </div>
  );
};

export default RadialChart;