

export interface Country {
  label: string;
  value: string;
}

export const COUNTRIES: Record<string, Country> = {
  AUSTRALIA: {
    label: "Australia",
    value: "au",
  },
  BRAZIL: {
    label: "Brazil",
    value: "br",
  },
  CANADA: {
    label: "Canada",
    value: "ca",
  },
  GERMANY: {
    label: "Germany",
    value: "de",
  },
  SPAIN: {
    label: "Spain",
    value: "es",
  },
  FRANCE: {
    label: "France",
    value: "fr",
  },
  UNITED_KINGDOM: {
    label: "United Kingdom",
    value: "gb",
  },
  IRELAND: {
    label: "Ireland",
    value: "ie",
  },
  ITALY: {
    label: "Italy",
    value: "it",
  },
  JAPAN: {
    label: "Japan",
    value: "jp",
  },
  SOUTH_KOREA: {
    label: "South Korea",
    value: "kr",
  },
  MEXICO: {
    label: "Mexico",
    value: "mx",
  },
  THAILAND: {
    label: "Thailand",
    value: "th",
  },
  UNITED_STATES: {
    label: "United States",
    value: "us",
  },
};

export interface Category {
  label: string;
  value: string;
}


export interface OTT {
  NETFLIX: Category;
  DISNEY_PLUS: Category;
  HBO_MAX: Category;
  HULU: Category;
  PRIME_VIDEO: Category;
  APPLE_TV_PLUS: Category;
  PEACOCK: Category;
  PARAMOUNT_PLUS: Category;
}

export interface IntentToWatch {
  WHERE_TO_WATCH: Category;
  CABLE: Category;
  PHYSICAL: Category;
  THEATRICAL: Category;
  OTT: OTT;
}

export interface IntentToPlayDigital {
  STEAM: Category;
  EPIC_GAMES: Category;
  GOG: Category;
  PLAYSTATION: Category;
  XBOX: Category;
}

export interface IntentToPlayPhysical {
  CONSOLE: Category;
  PC: Category;
}

export interface IntentToPlay {
  WHERE_TO_PLAY: Category;
  DIGITAL: IntentToPlayDigital;
  PHYSICAL: IntentToPlayPhysical;
}

export interface LearnAbout {
  GENERAL: Category;
  NEWS: Category;
  SOCIAL_MEDIA: Category;
  AWARDS: Category;
}

export interface Categories {
  GENERAL: {
    CATEGORY: Category;
    RELEASE_YEAR: Category;
  };
  INTENT_TO_WATCH: IntentToWatch;
  INTENT_TO_PLAY: IntentToPlay;
  LEARN_ABOUT: LearnAbout;
  REVIEWS: Category;
}

export const CATEGORIES: Categories = {
  GENERAL: {
    CATEGORY: {
      label: "Title and category",
      value: "general.category",
    },
    RELEASE_YEAR: {
      label: "Title and release year",
      value: "general.release_year",
    },
  },
  INTENT_TO_WATCH: {
    WHERE_TO_WATCH: {
      label: "Intent to watch - Where to watch",
      value: "intent_to_watch.where_to_watch",
    },
    CABLE: {
      label: "Intent to watch - Cable",
      value: "intent_to_watch.cable",
    },
    PHYSICAL: {
      label: "Intent to watch - Physical",
      value: "intent_to_watch.physical",
    },
    THEATRICAL: {
      label: "Intent to watch - Theatrical",
      value: "intent_to_watch.theatrical",
    },
    OTT: {
      NETFLIX: {
        label: "Intent to watch - Netflix",
        value: "intent_to_watch.ott.netflix",
      },
      DISNEY_PLUS: {
        label: "Intent to watch - Disney+",
        value: "intent_to_watch.ott.disneyplus",
      },
      HBO_MAX: {
        label: "Intent to watch - HBO Max",
        value: "intent_to_watch.ott.hbomax",
      },
      HULU: {
        label: "Intent to watch - Hulu",
        value: "intent_to_watch.ott.hulu",
      },
      PRIME_VIDEO: {
        label: "Intent to watch - Amazon Prime Video",
        value: "intent_to_watch.ott.amazonprimevideo",
      },
      APPLE_TV_PLUS: {
        label: "Intent to watch - Apple TV+",
        value: "intent_to_watch.ott.appletvplus",
      },
      PEACOCK: {
        label: "Intent to watch - Peacock",
        value: "intent_to_watch.ott.peacock",
      },
      PARAMOUNT_PLUS: {
        label: "Intent to watch - Paramount Plus",
        value: "intent_to_watch.ott.paramountplus",
      },
    },
  },
  INTENT_TO_PLAY: {
    WHERE_TO_PLAY: {
      label: "Intent to play - Where to play",
      value: "intent_to_play.where_to_play",
    },
    DIGITAL: {
      STEAM: {
        label: "Intent to play - Steam",
        value: "intent_to_play.digital.steam",
      },
      EPIC_GAMES: {
        label: "Intent to play - Epic Games",
        value: "intent_to_play.digital.epicgames",
      },
      GOG: {
        label: "Intent to play - GOG",
        value: "intent_to_play.digital.gog",
      },
      PLAYSTATION: {
        label: "Intent to play - PlayStation",
        value: "intent_to_play.digital.playstation",
      },
      XBOX: {
        label: "Intent to play - Xbox",
        value: "intent_to_play.digital.xbox",
      },
    },
    PHYSICAL: {
      CONSOLE: {
        label: "Intent to play - Console",
        value: "intent_to_play.physical.console",
      },
      PC: {
        label: "Intent to play - PC",
        value: "intent_to_play.physical.pc",
      },
    },
  },
  LEARN_ABOUT: {
    GENERAL: {
      label: "Learn about - General",
      value: "learn_about.general",
    },
    NEWS: {
      label: "Learn about - News",
      value: "learn_about.news",
    },
    SOCIAL_MEDIA: {
      label: "Learn about - Social media",
      value: "learn_about.social",
    },
    AWARDS: {
      label: "Learn about - Awards",
      value: "learn_about.awards",
    },
  },
  REVIEWS: {
    label: "Reviews",
    value: "reviews",
  },
};