import React, { useMemo, useState, useRef, useEffect, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import { AgCharts } from "ag-charts-react";
import { SpaceBetween } from "@cloudscape-design/components";
import CrosslineNotes from "./CrosslineNotes";
import { CompareContext } from "../../DatapointComparison";
import { CROSSLINE_TYPE_OPTIONS } from "../../constants";
import ChartOptions, { axisTypeOptions, granularityOptions } from "./ChartOptions";
import { useChartConfig } from "./hooks/useChartConfig";
import { useDownloadGridConfig } from "./hooks/useDownloadGridConfig";
import { CrosshairBoundingBox } from "./CrosshairBoundingBox";
import { convertData } from "../../utils";

const TimeseriesChart = ({ 
  metrics, 
  titles, 
  timeseriesData, 
  crosslineData, 
  multiParamInfo, 
  unitSuffix, 
  decimalDigits,
  reverseYAxis=false,
}) => {

  const options = useContext(CompareContext);

  const chartRef = useRef(null);
  const exportTableRef = useRef(null);

  const [ titleMetricInfo, setTitleMetricInfo ] = useState([]);
  const [ chartBaseTheme, setChartBaseTheme ] = useState(null);
  const [ selectedAxisType, setSelectedAxisType ] = useState(axisTypeOptions[0]);
  const [ selectedGranularity, setSelectedGranularity ] = useState(granularityOptions.find(o => o.value === "day"));
  const [ selectedCrosslineTypes, setSelectedCrosslineTypes ] = useState(CROSSLINE_TYPE_OPTIONS);

  const { chartOptions, crosslines } = useChartConfig({
    metrics: metrics,
    titles: titles,
    chartHeight: options.chart.height,
    multiParamInfo: multiParamInfo,
    timeseriesData: timeseriesData,
    titleMetricInfo: titleMetricInfo,
    setTitleMetricInfo: setTitleMetricInfo,
    crosslineData: crosslineData,
    selectedCrosslineTypes: selectedCrosslineTypes,
    selectedAxisType: selectedAxisType,
    selectedGranularity: selectedGranularity,
    unitSuffix: unitSuffix,
    decimalDigits: decimalDigits,
    reverseYAxis: reverseYAxis,
    chartBaseTheme: chartBaseTheme,
  });

  const { gridOptions: downloadGridOptions } = useDownloadGridConfig({
    timeseriesData: timeseriesData,
    crosslineData: crosslineData,
    titleMetricInfo: titleMetricInfo,
  });

  return (
    <SpaceBetween direction="vertical" size="s">
      <ChartOptions
        metrics={metrics}
        exportTableRef={exportTableRef}
        chartRef={chartRef}
        selectedAxisType={selectedAxisType}
        setSelectedAxisType={setSelectedAxisType}
        selectedGranularity={selectedGranularity}
        setSelectedGranularity={setSelectedGranularity}
        selectedCrosslineTypes={selectedCrosslineTypes}
        setSelectedCrosslineTypes={setSelectedCrosslineTypes}
        chartBaseTheme={chartBaseTheme}
        setChartBaseTheme={setChartBaseTheme}
      />
      <div 
        style={{ height: `${options.chart.height}px`, width: "100%", position: "relative" }} 
        className="ag-theme-quartz-dark" 
      >
        <AgCharts
          ref={chartRef}
          options={chartOptions}
        />
        <CrosshairBoundingBox chartRef={chartRef} />
        {options.chart.chartNotes.enabled && (
          <CrosslineNotes
            chartRef={chartRef}
            crosslines={crosslines.filter(c => selectedCrosslineTypes.map(c => c.value).includes(c.event_type))}
          />
        )}
      </div>
      <div style={{ height: "450px", width: "100%" }} className="ag-theme-quartz-dark hidden">
        <AgGridReact
          ref={exportTableRef}
          {...downloadGridOptions}
        />
      </div>
    </SpaceBetween>
  );
};

export default TimeseriesChart;