import { ExpandableSection } from "@cloudscape-design/components";
import { AgCharts } from "ag-charts-react";
import { AgChartOptions } from "ag-grid-enterprise";
import React, { FC } from "react";
import { useGetTrackedRedditData } from "../../../../api/hooks/useGetTrackedRedditData";
import { DateRangeType } from "../../DateRange";
import { useChartConfig } from "./useChartConfig";

interface RedditTrendsProps {
  ipId: string;
  dateRange: DateRangeType;
}

export const RedditTrends: FC<RedditTrendsProps> = ({ ipId, dateRange }) => {
  const { data: redditTrendsData } = useGetTrackedRedditData({
    ipId,
    dateRange,
  });
  const { redditTrendChartOption } = useChartConfig(redditTrendsData);

  return (
    <ExpandableSection
      variant="container"
      defaultExpanded
      headerText="Reddit Trends"
    >
      {redditTrendChartOption && (
        <div className="h-80">
          <AgCharts
            options={redditTrendChartOption as any | AgChartOptions}
            style={{ height: "100%" }}
          />
        </div>
      )}
    </ExpandableSection>
  );
};
