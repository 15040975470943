import {
  Container,
  ContentLayout,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Select,
  SpaceBetween,
  TextFilter,
} from "@cloudscape-design/components";
import React, { useState } from "react";
import { navItems } from "../../../../layouts/common/menu/side-menu";
import { Layout } from "../../Layout";
import { BoByStudioChart } from "./components/BoByStudioChart";
import { BoxOfficeShareChart } from "./components/BoMarketshareChart";
import { useBODataProcessing } from "./api/hooks/useBODataProcessing";
import BoStudioMovieGrid from "./components/BoStudioMovieGrid";

export const BoByStudioPage = () => {
  const breadcrumbs = [
    { text: "Tools" },
    { text: "Box Office Analysis", href: "/box-office" },
    { text: "Box Office By Distributor" },
  ];
  const [filterText, setFilterText] = useState("");
  const currentYear = new Date().getFullYear();
  const startYear = 1980;
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index,);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleYearChange = (event) => {
    setSelectedYear(event.detail.selectedOption.value);
  };

  const { processedData, isLoading, error } = useBODataProcessing(selectedYear);

  return (
    <Layout
      title="Box Office By Distributor"
      breadcrumbs={breadcrumbs}
      navItems={navItems}
      content={
        <ContentLayout
          header={
            <Header
              variant="h2"
              actions={
                <FormField description="Release year">
                  <Select
                    options={[
                      ...years
                        .slice()
                        .sort()
                        .reverse()
                        .map((y) => ({ label: y, value: y })),
                    ]}
                    placeholder="Select a year"
                    selectedOption={{ label: selectedYear.toString(), value: selectedYear }}
                    onChange={handleYearChange}
                  />
                </FormField>
              }
            >
              Box Office By Distributor
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <ExpandableSection
              defaultExpanded
              variant="stacked"
              headerText={`BoxOffice Collections by Distributors for ${selectedYear} (Top 15)`}
            >
              <BoByStudioChart data={processedData} isLoading={isLoading} error={error} />
            </ExpandableSection>
            <ExpandableSection
              defaultExpanded
              disableContentPaddings
              variant="stacked"
              headerText={`BoxOffice Market Share by Distributors for ${selectedYear} (Top 15)`}>
              <Grid gridDefinition={[{ colspan: { xxs: 12, m: 6 } }, { colspan: { xxs: 12, m: 6 } }]}>
                <BoxOfficeShareChart data={processedData} isLoading={isLoading} error={error} marketShareKey="domesticMarketShare" title="Domestic" />
                <BoxOfficeShareChart data={processedData} isLoading={isLoading} error={error} marketShareKey="internationalMarketShare" title="International" />
              </Grid>
            </ExpandableSection>
            <ExpandableSection
              defaultExpanded
              disableContentPaddings
              variant="stacked"
              headerText={`Titles by Distributors for ${selectedYear}`}
              headerActions={
                <TextFilter
                  filteringText={filterText}
                  filteringPlaceholder="Search title"
                  filteringAriaLabel="Filter title"
                  onChange={({ detail }) => setFilterText(detail.filteringText)}
                />
              }
            >
              {processedData && processedData.length > 0 && !isLoading && !error && (
                <BoStudioMovieGrid data={processedData} isLoading={isLoading} error={error} filterText={filterText} />
              )}
            </ExpandableSection>
          </SpaceBetween>
        </ContentLayout>
      }
      contentType="table"
    />
  );
};
