import {
  Box,
  Button,
  ColumnLayout,
  Container,
  ExpandableSection,
  Grid,
  Header,
  Link,
  SpaceBetween,
  Table,
  Tabs,
  TextContent,
} from "@cloudscape-design/components";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AddFranchiseIcon from "../../../../assets/images/AddFranchiseIcon";
import CompareIcon from "../../../../assets/images/CompareIcon";
import { useAuthContext } from "../../../../auth/useAuthContext";
import { IP_LIST, TAGS_LIST } from "../../../../config-global";
import { formatDate } from "../../../../utils/dateFormatter";
import { addQueryParams, getQueryParams } from "../../../../utils/queryUtils";
import { useAddYoutubeVideos } from "../../api/hooks/useAddYoutubeVideos";
import { calculateStartDate, isDataAvailable } from "../../utils";
import { DateRange } from "../components/DateRange";
import NewsBox from "../components/News";
import IMDbScore from "../components/Scores/IMDbScore";
import { SCORE_TYPES, Score } from "../components/Scores/Score";
import TagList from "../components/TagList";
import TagsAndIDsComponent from "../components/TagsAndIDsComponent";
import TrailerGrid from "../components/Trailer/Trailer";
import { ImdbTrends } from "../components/Trends/ImdbTrends/ImdbTrends";
import { RedditTrends } from "../components/Trends/RedditTrends/RedditTrends";
import SocialTrends from "../components/Trends/SocialTrends";
import { SteamTrends } from "../components/Trends/SteamTrends/SteamTrends";
import { TwitchTrends } from "../components/Trends/TwitchTrends/TwitchTrends";
import { WikipediaTrends } from "../components/Trends/WikipediaTrends/WikipediaTrends";
import { YoutubeTrends } from "../components/Trends/YoutubeTrends/YoutubeTrends";
import WikiSummaryBox from "../components/WikipediaTab";
import { AddToFranchiseModal } from "../components/modals/AddToFranchiseModal";
import { AddYoutubeTrailerModal } from "../components/modals/AddYoutubeTrailerModal";
import AttributesEditModal from "../components/modals/AttributesEditModal";
import AuditModal from "../components/modals/AuditModal";
import { CompareModal } from "../components/modals/CompareModal/CompareModal";
import {
  DATA_COMPARE_METRICS,
  SCORE_COMPARE_METRICS,
} from "../components/modals/CompareModal/metrics";
import { DeleteConfirmationModal } from "../components/modals/DeleteConfirmationModal";
import { TrackUnTrackConfirmationModal } from "../components/modals/TrackUnTrackConfirmationModal";
import { useGetPlatformData } from "./api/hooks/useGetPlatformData";
import AddOnsComponent from "./components/AddOnGames";
import {
  MetacriticScore,
  MetacriticUserScore,
  SteamReviews,
} from "./components/GamingScores";
import RankingTable from "../components/RankingTable";
import { RankTrends } from "../components/Trends/RankTrends";

const defaultDateRange = {
  type: "absolute",
  startDate: "2020-01-01",
  endDate: moment().format("YYYY-MM-DD"),
};

export default function ItemVideoGame({
  itemData,
  onTrackItem,
  onUntrackItem,
  onDelete,
}) {
  const { user } = useAuthContext();
  const [isTracked, setIsTracked] = useState(false);
  const [showTrackUnTrackModal, setShowTrackUnTrackModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddFranchiseModal, setShowAddFranchiseModal] = useState(false);
  const [showAuditModal, setShowAuditModal] = useState(false);
  const onShowAuditInit = () => setShowAuditModal(true);
  const [showAddYoutubeTrailerModal, setShowAddYoutubeTrailerModal] =
    useState(false);
  const [showAttrModal, setShowAttrModal] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [addTrack, setAddTrack] = useState(false);
  const onTrackUnTrackInit = () => setShowTrackUnTrackModal(true);
  const onTrackUnTrackClose = () => setShowTrackUnTrackModal(false);
  const onDeleteModalInit = () => setShowDeleteModal(true);
  const onDeleteModalClose = () => setShowDeleteModal(false);
  const onAddFranchiseModalInit = () => setShowAddFranchiseModal(true);
  const onAddFranchiseModalClose = () => setShowAddFranchiseModal(false);
  const onShowCompareModalInit = () => setShowCompareModal(true);
  const onShowAttrInit = () => setShowAttrModal(true);
  const onShowAttrClose = () => setShowAttrModal(false);
  const [triggerTrailersApi, setTriggerTrailersApi] = useState(true);

  const tabQuery = getQueryParams("tab");
  const [activeTabId, setActiveTabId] = useState(tabQuery ?? "rtrends");

  const releaseDate = itemData?.data?.release_date;
  const year = new Date(releaseDate).getFullYear();
  const compareMetrics = [
    ...SCORE_COMPARE_METRICS.ALL,
    ...SCORE_COMPARE_METRICS.GAMES,
    ...DATA_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.GAMES,
  ];

  const [dateRange, setDateRange] = useState({
    ...defaultDateRange,
    startDate: calculateStartDate(releaseDate),
  });

  const categoryNames = {
    1: "Official",
    2: "Wikia",
    3: "Wikipedia",
    4: "Facebook",
    5: "Twitter",
    6: "Twitch",
    8: "Instagram",
    9: "YouTube",
    10: "iPhone",
    11: "iPad",
    12: "Android",
    13: "Steam",
    14: "Reddit",
    15: "Itch",
    16: "Epic Games",
    17: "GOG",
    18: "Discord",
  };

  useEffect(() => {
    if (itemData.data && itemData.data.tracked) {
      setIsTracked(itemData.data.tracked);
    }
  }, [itemData]);

  const checkIpList = (ip_list) => {
    return _.every(IP_LIST, ({ value }) => ip_list?.includes(value));
  };

  const isAdmin = user?.role === "superAdmin" || user?.role === "admin";
  useEffect(() => {
    onShowAttrClose();
  }, [itemData]);

  const onSuccess = () => {
    setTriggerTrailersApi(true);
    setShowAddYoutubeTrailerModal(false);
  };

  const { mutate: addYoutubeVideos } = useAddYoutubeVideos(onSuccess);
  const { data: twitchTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "twitch",
    dateRange,
  });
  const { data: steamTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "steam",
    dateRange,
  });
  const { data: youtubeTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "youtube",
    dateRange,
  });
  const { data: imdbTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "imdb",
    dateRange,
  });
  const { data: wikipediaTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "wiki",
    dateRange,
  });

  const handleTabChange = ({ detail }) => {
    setActiveTabId(detail.activeTabId);
    addQueryParams({ tab: detail.activeTabId });
  };

  return (
    <Container
      variant="borderless"
      disableContentPaddings
      disableHeaderPaddings
      header={
        <Header
          className="mb-4"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                ariaLabel="Track Title"
                variant="normal"
                iconName="check"
                onClick={() => {
                  setAddTrack(true);
                  onTrackUnTrackInit();
                }}
                disabled={
                  !isAdmin
                    ? true
                    : checkIpList(itemData?.data?.ip_list) ||
                    itemData?.data?.tracked
                }
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to track this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Untrack Title"
                variant="normal"
                iconName="close"
                onClick={() => {
                  setAddTrack(false);
                  onTrackUnTrackInit();
                }}
                disabled={!isAdmin ? true : !itemData?.data?.tracked}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to untrack this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Edit Tags"
                variant="normal"
                iconName="edit"
                onClick={() => onShowAttrInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin ? "You don't have permission to edit tags." : ""
                }
              />
              <Button
                ariaLabel="Compare Titles"
                variant="normal"
                onClick={() => onShowCompareModalInit()}
                disabled={false}
                disabledReason="This feature is currently unavailable."
                iconSvg={<CompareIcon />}
              />
              <Button
                ariaLabel="Add to Franchise"
                variant="normal"
                onClick={() => onAddFranchiseModalInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to add to franchise."
                    : ""
                }
                iconSvg={<AddFranchiseIcon />}
              />
              <Button
                ariaLabel="Add Youtube Trailer"
                variant="normal"
                onClick={() => setShowAddYoutubeTrailerModal(true)}
                iconSvg={
                  <svg
                    fill="#000000"
                    width="800px"
                    height="800px"
                    viewBox="-2 -5 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMinYMin"
                    className="jam jam-youtube"
                  >
                    <path d="M15.812.017H4.145C1.855.017 0 1.852 0 4.116v5.768c0 2.264 1.856 4.1 4.145 4.1h11.667c2.29 0 4.145-1.836 4.145-4.1V4.116c0-2.264-1.856-4.1-4.145-4.1zM13.009 7.28L7.552 9.855a.219.219 0 0 1-.314-.196V4.35c0-.161.173-.266.318-.193l5.458 2.735a.216.216 0 0 1-.005.389z" />
                  </svg>
                }
              />
              <Button
                ariaLabel="Audit Logs"
                iconName="security"
                variant="normal"
                onClick={() => {
                  console.log("audit logs");
                  onShowAuditInit();
                }}
              />

              <Button
                ariaLabel="Delete Title"
                iconName="remove"
                variant="normal"
                onClick={() => onDeleteModalInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to delete this title."
                    : ""
                }
              />
            </SpaceBetween>
          }
        >
          <div>
            <div className="flex items-center">
              <div className="text-xl font-bold inline-block">
                {itemData.data.name}
                {itemData.data.status && (
                  <span className="text-slate-500">
                    {" "}
                    ({itemData.data.status})
                  </span>
                )}
              </div>

              <div className="inline-block whitespace-nowrap ml-2">
                {isTracked ? (
                  <div className="flex space-x-2">
                    {itemData.data?.ip_list?.length > 0 ? (
                      itemData.data.ip_list.map((ip) => (
                        <span
                          key={ip}
                          className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700"
                        >
                          <svg
                            className="h-1.5 w-1.5 fill-green-400"
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {ip}
                        </span>
                      ))
                    ) : (
                      <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/30">
                        Tracked
                      </span>
                    )}
                  </div>
                ) : (
                  <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700">
                    <svg
                      className="h-1.5 w-1.5 fill-slate-400"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    Not tracked
                  </span>
                )}
              </div>
            </div>
            <div className="dark:text-slate-400 text-slate-600 text-sm font-normal">
              {[
                itemData.data.type.name,
                ...(itemData.data.release_date ? [(new Date(Date.parse(itemData.data.release_date)) > new Date()
                  ? `${formatDate(
                    new Date(Date.parse(itemData.data.release_date)),
                  )} (${moment(itemData.data.release_date).fromNow()})`
                  : formatDate(
                    new Date(Date.parse(itemData.data.release_date)),
                  ))] : []),
              ].join(" \u2022 ")}
            </div>
          </div>
        </Header>
      }
    >
      <Container>
        <div className="space-y-6">
          <div className="flex space-x-6">
            <img
              src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${itemData.data.image_url}.png`}
              className="w-64 drop-shadow-lg h-min rounded-md dark:border-slate-600 border-slate-400 border-solid border"
            />
            <div className="flex flex-col justify-between">
              <div className="space-y-1">
                <TagList
                  title="Genre"
                  tagData={itemData.data.genre.map((genre) => ({
                    label: genre,
                    color: "purple",
                  }))}
                />
                {itemData.data.cTags && itemData.data.cTags.length > 0 && (
                  <TagList
                    title="Tags"
                    tagData={itemData.data.cTags.map((cTags) => ({
                      label: cTags,
                      color: "green",
                    }))}
                  />
                )}
                {itemData.data.franchise &&
                  itemData.data.franchise.length > 0 && (
                    <TagList
                      title="Franchises"
                      tagData={itemData.data.franchise.map((franchise) => ({
                        label: franchise.name,
                        color: "green",
                        href: `/item/${franchise.id}`,
                      }))}
                    />
                  )}
                {itemData.data.involved_companies &&
                  itemData.data.involved_companies.filter(
                    (x) => x.publisher === true,
                  ).length > 0 && (
                    <TagList
                      title="Publishers"
                      tagData={itemData.data.involved_companies
                        .filter((x) => x.publisher === true)
                        .map((publisher) => ({
                          label: publisher.company.name,
                          color: "red",
                        }))}
                    />
                  )}
                {itemData.data.involved_companies &&
                  itemData.data.involved_companies.filter(
                    (x) => x.developer === true,
                  ).length > 0 && (
                    <TagList
                      title="Developers"
                      tagData={itemData.data.involved_companies
                        .filter((x) => x.developer === true)
                        .map((developer) => ({
                          label: developer.company.name,
                          color: "indigo",
                        }))}
                    />
                  )}
                {itemData.data.release_dates && (
                  <TagList
                    title="Platforms"
                    tagData={[
                      ...new Set(
                        itemData.data.release_dates.map((x) => x.platform),
                      ),
                    ].map((platform) => ({
                      label: platform,
                      color: "amber",
                    }))}
                  />
                )}
              </div>
              <div className="pt-4">
                <SpaceBetween direction="vertical" size="s">
                  <SpaceBetween direction="horizontal" size="xl">
                    {!!itemData.data.steam &&
                      !!itemData.data.steam_rating_description &&
                      (itemData.data.steam_rating_positive !== 0 ||
                        itemData.data.steam_rating_negative !== 0) &&
                      itemData.data.steam_rating_description !==
                      "No user reviews" ? (
                      <SteamReviews
                        positiveReviews={itemData.data.steam_rating_positive}
                        negativeReviews={itemData.data.steam_rating_negative}
                        ratingDescription={
                          itemData.data.steam_rating_description
                        }
                      />
                    ) : null}

                    {!!itemData.data.metacritic_rating && (
                      <MetacriticScore
                        score={itemData.data.metacritic_rating}
                        votes={itemData?.data?.metacritic_vote_count}
                      />
                    )}
                    {!!itemData?.data?.metacritic_user_rating &&
                      !!itemData?.data?.metacritic_user_vote_count && (
                        <MetacriticUserScore
                          score={itemData.data.metacritic_user_rating}
                          votes={itemData?.data?.metacritic_user_vote_count}
                        />
                      )}
                    {!!itemData.data.imdb_vote_average && (
                      <IMDbScore
                        score={itemData.data.imdb_vote_average}
                        votes={itemData.data.imdb_vote_count}
                      />
                    )}
                  </SpaceBetween>
                  <SpaceBetween direction="horizontal" size="xl">
                    {itemData.data?.global_rank?.pantheon && (
                      <Score
                        type={SCORE_TYPES.PANTHEON}
                        rank={itemData.data.global_rank?.pantheon?.daily_rank}
                        weeklyRank={
                          itemData.data.global_rank?.pantheon?.weekly_rank
                        }
                      />
                    )}
                    {itemData.data?.ip_ranking?.wiki && (
                      <Score
                        type={SCORE_TYPES.WIKIPEDIA}
                        rank={itemData.data?.ip_ranking?.wiki?.daily_rank}
                        weeklyRank={itemData.data?.ip_ranking?.wiki?.weekly_rank}
                      />
                    )}
                    {itemData.data?.ip_ranking?.twitch && (
                      <Score
                        type={SCORE_TYPES.TWITCH}
                        rank={itemData.data?.ip_ranking?.twitch?.daily_rank}
                        weeklyRank={
                          itemData.data?.ip_ranking?.twitch?.weekly_rank
                        }
                      />
                    )}
                    {itemData.data?.ip_ranking?.steam && (
                      <Score
                        type={SCORE_TYPES.STEAM}
                        rank={itemData.data?.ip_ranking?.steam?.daily_rank}
                        weeklyRank={
                          itemData.data?.ip_ranking?.steam?.weekly_rank
                        }
                      />
                    )}
                    {itemData.data?.ip_ranking?.youtube && (
                      <Score
                        type={SCORE_TYPES.YOUTUBE}
                        rank={itemData.data?.ip_ranking?.youtube?.daily_rank}
                        weeklyRank={
                          itemData.data?.ip_ranking?.youtube?.weekly_rank
                        }
                      />
                    )}
                    {/* {isDataAvailable(imdbTrendsData) && (
                          <Score
                            type={SCORE_TYPES.IMDB}
                            rank={imdbStat?.dailyRank}
                            views={imdbStat?.allTimeRank}
                          />
                        )} */}
                  </SpaceBetween>
                </SpaceBetween>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="flex space-x-6 mt-4">
        <div className="w-full">
          <Tabs
            onChange={handleTabChange}
            activeTabId={activeTabId}
            tabs={[
              {
                label: "Rank Trends",
                id: "rtrends",
                content: (
                  <SpaceBetween direction="vertical" size="l">
                    <RankingTable ip_ranking={itemData.data.ip_ranking} />
                    <RankTrends
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      wikipediaTrendsData={wikipediaTrendsData}
                      twitchTrendsData={twitchTrendsData}
                      steamTrendsData={steamTrendsData}
                      youtubeTrendsData={youtubeTrendsData}
                      imdbTrendsData={imdbTrendsData}
                    />
                  </SpaceBetween>
                ),
              },
              {
                label: "Platform Trends",
                id: "advancedTrends",
                content: (
                  <SpaceBetween direction="vertical" size="l">
                    <Box float="right" variant="p">
                      <DateRange
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                      />
                    </Box>
                    {itemData?.data?.tracked && itemData?.data?.instagram && (
                      <ExpandableSection
                        defaultExpanded
                        headingTagOverride="h4"
                        variant="container"
                        headerText="Instagram Trends"
                      >
                        <SocialTrends itemData={itemData} />
                      </ExpandableSection>
                    )}
                    <Grid
                      gridDefinition={[
                        { colspan: { default: 12, m: 6 } },
                        { colspan: { default: 12, m: 6 } },
                        { colspan: { default: 12, m: 6 } },
                        { colspan: { default: 12, m: 6 } },
                        { colspan: { default: 12, m: 6 } },
                      ]}
                    >
                      {isDataAvailable(wikipediaTrendsData) && (
                        <WikipediaTrends
                          data={wikipediaTrendsData}
                          dateRange={dateRange}
                          configType="advanced"
                        />
                      )}
                      {isDataAvailable(twitchTrendsData) && (
                        <TwitchTrends
                          data={twitchTrendsData}
                          dateRange={dateRange}
                          configType="advanced"
                        />
                      )}
                      {isDataAvailable(steamTrendsData) && (
                        <SteamTrends
                          data={steamTrendsData}
                          dateRange={dateRange}
                          configType="advanced"
                        />
                      )}
                      {isDataAvailable(youtubeTrendsData) && (
                        <YoutubeTrends
                          data={youtubeTrendsData}
                          dateRange={dateRange}
                          configType="advanced"
                        />
                      )}
                      {isDataAvailable(imdbTrendsData) && (
                        <ImdbTrends
                          data={imdbTrendsData}
                          dateRange={dateRange}
                          configType="advanced"
                        />
                      )}
                    </Grid>

                    <RedditTrends
                      itemData={itemData?.data?.ip_id}
                      dateRange={dateRange}
                    />
                  </SpaceBetween>
                ),
              },
              {
                label: "Summary",
                id: "wiki",
                content: (
                  <WikiSummaryBox
                    id={itemData.data.wikidata_id}
                    summary={itemData.data.summary}
                  />
                ),
              },
              ...((itemData.data.dlcs && itemData.data.dlcs.length > 0) ||
                (itemData.data.bundles && itemData.data.bundles.length > 0) ||
                (itemData.data.expansions &&
                  itemData.data.expansions.length > 0) ||
                (itemData.data.remakes && itemData.data.remakes.length > 0) ||
                (itemData.data.remasters && itemData.data.remasters.length > 0)
                ? [
                  {
                    label: "Add-ons",
                    id: "addons",
                    content: (
                      <AddOnsComponent
                        dlcs={itemData.data.dlcs}
                        bundles={itemData.data.bundles}
                        expansions={itemData.data.expansions}
                        remakes={itemData.data.remakes}
                        remasters={itemData.data.remasters}
                      />
                    ),
                  },
                ]
                : []),
              {
                label: "IDs",
                id: "tags",
                content: (
                  <SpaceBetween direction="vertical" size="m">
                    <ExpandableSection
                      defaultExpanded
                      headingTagOverride="h4"
                      variant="container"
                      headerText="Metadata"
                    >
                      <ColumnLayout
                        columns={4}
                        minColumnWidth={150}
                        variant="text-grid"
                      >
                        {itemData.data?.game_modes && (
                          <div>
                            <TextContent>
                              <h4>Game Modes</h4>
                            </TextContent>
                            {itemData.data.game_modes.map((x, index) => (
                              <div key={index}>{x}</div>
                            ))}
                          </div>
                        )}
                        <div>
                          {itemData.data?.themes && (
                            <div>
                              <TextContent>
                                <h4>Themes</h4>
                              </TextContent>
                              {itemData.data.themes.map((x) => (
                                <div>{x}</div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div>
                          {itemData.data?.player_perspectives && (
                            <div>
                              <TextContent>
                                <h4>Player Perspectives</h4>
                              </TextContent>
                              {itemData.data.player_perspectives.map((x) => (
                                <div>{x.name}</div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div>
                          {itemData.data?.game_engine && (
                            <div>
                              <TextContent>
                                <h4>Game Engines</h4>
                              </TextContent>
                              {itemData.data.game_engine.map((x) => (
                                <div>{x.name}</div>
                              ))}
                            </div>
                          )}
                        </div>
                      </ColumnLayout>
                    </ExpandableSection>
                    <ExpandableSection
                      defaultExpanded
                      headingTagOverride="h4"
                      variant="container"
                      headerText="Official Links"
                    >
                      <Table
                        columnDefinitions={[
                          {
                            id: "category",
                            header: "Category Name",
                            cell: (item) => item.categoryName || "-",
                            isRowHeader: true,
                          },
                          {
                            id: "link",
                            header: "Link",
                            cell: (item) => (
                              <Link href={item.url} external rel="noreferrer">
                                {item.url}
                              </Link>
                            ),
                          },
                        ]}
                        enableKeyboardNavigation
                        items={itemData.data.websites?.map((link) => ({
                          categoryName: categoryNames[link.category],
                          url: link.url,
                        }))}
                        loadingText="Loading links..."
                        variant="embedded"
                        empty={
                          <Box
                            margin={{ vertical: "xs" }}
                            textAlign="center"
                            color="inherit"
                          >
                            <SpaceBetween size="m">
                              <b>No official links available</b>
                            </SpaceBetween>
                          </Box>
                        }
                      />
                    </ExpandableSection>
                    <ExpandableSection
                      defaultExpanded
                      headingTagOverride="h4"
                      variant="container"
                      headerText="IDs"
                      headerActions={
                        <Button
                          variant="normal"
                          iconName="edit"
                          onClick={() => onShowAttrInit()}
                          disabled={!isAdmin}
                          disabledReason={
                            !isAdmin ? "You don't have permission to edit." : ""
                          }
                        >
                          Edit
                        </Button>
                      }
                    >
                      <TagsAndIDsComponent itemData={itemData.data} />
                    </ExpandableSection>
                  </SpaceBetween>
                ),
              },
              {
                label: "Trailers",
                id: "Trailers",
                content: (
                  <TrailerGrid
                    id={itemData.data.ip_id}
                    triggerTrailersApi={triggerTrailersApi}
                    setTriggerTrailersApi={setTriggerTrailersApi}
                    setShowAddYoutubeTrailerModal={
                      setShowAddYoutubeTrailerModal
                    }
                  />
                ),
              },
              {
                label: "News",
                id: "news",
                content: <NewsBox id={`${itemData?.data?.ip}+${year}`} />,
              },
            ]}
          />
        </div>
      </div>

      <TrackUnTrackConfirmationModal
        visible={showTrackUnTrackModal}
        ipLists={IP_LIST}
        track={!addTrack}
        itemData={itemData?.data}
        ip_id={itemData?.data?.ip_id}
        defaultSelectedIps={itemData?.data?.ip_list}
        onDiscard={onTrackUnTrackClose}
        consentText={itemData?.data?.name}
        onConfirm={(item) => {
          if (item.track) {
            onUntrackItem(item);
            onTrackUnTrackClose();
          } else {
            onTrackItem(item);
            onTrackUnTrackClose();
          }
        }}
      />
      <DeleteConfirmationModal
        instances={[itemData?.data]}
        visible={showDeleteModal}
        onDiscard={onDeleteModalClose}
        onDelete={() => onDelete(itemData?.data?.ip_id)}
        consentText={itemData?.data?.name}
      />
      <AddToFranchiseModal
        visible={showAddFranchiseModal}
        onDiscard={onAddFranchiseModalClose}
        itemData={itemData}
      />
      <AddYoutubeTrailerModal
        visible={showAddYoutubeTrailerModal}
        onSubmit={(videoIds) => {
          addYoutubeVideos({
            videoIds,
            ip: itemData?.data?.ip,
            ipId: itemData?.data?.ip_id,
          });
        }}
        onDiscard={() => setShowAddYoutubeTrailerModal(false)}
      />
      <AttributesEditModal
        visible={showAttrModal}
        itemData={itemData?.data}
        onDismiss={() => setShowAttrModal(false)}
        editableAttributes={TAGS_LIST.map((x) => x.value).filter(
          (x) => !["youtubeChannel", "tmsId", "emsId", "rtUrl"].includes(x),
        )}
      />
      <AuditModal
        visible={showAuditModal}
        ip_id={itemData?.data?.ip_id}
        onDiscard={() => setShowAuditModal(false)}
      />
      <CompareModal
        visible={showCompareModal}
        setVisible={setShowCompareModal}
        metrics={compareMetrics}
        titleId={itemData?.data?.ip_id}
        metricKeys={[
          ...SCORE_COMPARE_METRICS.ALL,
          ...SCORE_COMPARE_METRICS.GAMES,
        ].map((x) => x.key)}
      />
    </Container>
  );
}
