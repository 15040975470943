import axiosInstance from "../../../../utils/axios";

export const getGoogleSearchData = ({
  ipIds=[],
  categories=[],
  geoNames=[],
  startDate,
  endDate,
}) => {
  if (!ipIds.length || !categories.length || !geoNames.length) {
    return Promise.resolve({ data: null });
  }

  return axiosInstance.request({
    url: "/google/search/getdata",
    method: "GET",
    params: {
      ip_ids: ipIds.join(","),
      categories: categories.join(","),
      geo_names: geoNames.join(","),
      start_date: startDate,
      end_date: endDate,
    },
  });
};
  