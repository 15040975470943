import React from "react";
import { SpaceBetween } from "@cloudscape-design/components";
import TitleSelector from "../../../../components/datapoint-comparison/components/Parameters/TitleSelector";

export const Parameters = ({
  setSelectedTitle,
}) => {
  const [searchQuery, setSearchQuery] = React.useState("");

  return (
    <div className="flex justify-between">
      <div>
        <TitleSelector
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onSelectedItemChanged={(item) => setSelectedTitle(item.originalData)}
        />
      </div>
      <SpaceBetween size="s" direction="horizontal">
      </SpaceBetween>
    </div>
  );
};