import { Spinner, Link, Icon } from "@cloudscape-design/components";
import React from "react";
import { useInstagramHashtagsData } from "../../../api/hooks/useGetInstagramHashtagsData";

const SocialTrends = ({ itemData }) => {
  const params =
    itemData?.data?.tracked && itemData?.data?.instagram
      ? { params: { tags: itemData.data.instagram.join(",") } }
      : null;

  const { data: instagramHashtagsData, isLoading } =
    useInstagramHashtagsData(params);

  return (
    <>
      {itemData?.data?.instagram && instagramHashtagsData?.data ? (
        <dl className=" grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
          {instagramHashtagsData.data.map((tagData, index) => {
            return (
              <div
                key={index}
                className="overflow-hidden rounded-xl dark:bg-darkerBg  shadow-lg p-6 sm:px-4"
              >
                <dt className="truncate text-base font-medium">
                  <Link
                    href={`https://instagram.com/explore/tags/${tagData.hashtag}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    #{tagData.hashtag} <Icon name="external" />
                  </Link>
                </dt>
                <dd className="flex items-baseline justify-between">
                  <span className="text-xs italic">
                    {isLoading ? (
                      <div className="text-center">
                        <Spinner />
                      </div>
                    ) : tagData ? (
                      `${tagData.num_posts.toLocaleString()} posts`
                    ) : (
                      "N/A"
                    )}
                  </span>
                </dd>
              </div>
            );
          })}
        </dl>
      ) : (
        <div className="text-center text-gray-500">No tags available</div>
      )}
    </>
  );
};

export default SocialTrends;
