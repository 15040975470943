import { useCallback } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../../../utils/shortenInteger";

const tooltip = {
  renderer: ({ datum }) => {
    const date = new Date(datum.timestamp);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const humanReadableDate = date.toLocaleString("en-US", options);
    const title = `<span style="font-size: 12px">${humanReadableDate}</span>`;

    return {
      title: title,
      content: `
                <b>Daily Rank:</b> ${datum.dailyRank}<br/>
                <b>Daily Vote:</b> ${shortenInteger(datum.dailyVotes)} <br />
                <b>All Time Votes:</b> ${shortenInteger(
                  datum.allTimeVotes,
                )}<br/>
                <b>All Time Rating:</b> ${datum.allTimeRating}<br/>
            `,
    };
  },
};

export const useChartConfig = (data) => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);

  const getLineChartOptions = useCallback(
    (axisTypeValue) => {
      return {
        padding: { right: 0, bottom: 0, left: 0 },
        minWidth: 0,
        minHeight: 0,
        theme: theme,
        legend: {
          position: "right",
          spacing: 8,
          item: {
            paddingY: 14,
            line: {
              strokeWidth: 12,
            },
          },
        },
        series: [
          {
            type: "line",
            xKey: "timestamp",
            yKey: "dailyVotes",
            yName: "Daily Votes",
            marker: { enabled: false },
            visible: false,
            tooltip,
          },
          {
            type: "line",
            xKey: "timestamp",
            yKey: "allTimeVotes",
            yName: "All Time Votes",
            marker: { enabled: false },
            tooltip,
          },
          {
            type: "line",
            xKey: "timestamp",
            yKey: "allTimeRating",
            yName: "All Time Rating",
            marker: { enabled: false },
            visible: false,
            tooltip,
          },
          {
            type: "line",
            xKey: "timestamp",
            yKey: "allTimeRank",
            yName: "All Time Ranking",
            marker: { enabled: false },
            visible: false,
            tooltip,
          },
        ],

        sync: {
          enabled: true,
          axes: "x",
          nodeInteraction: true,
        },
        zoom: {
          enabled: true,
          enableSelecting: true,
        },
        axes: [
          {
            type: "ordinal-time",
            position: "bottom",
            label: {
              format: "%b %-d, %Y",
              fontSize: 10,
            },
            crosshair: {
              label: {
                format: "%b %-d, %Y",
              },
            },
          },
          {
            // type: axisTypeValue || "number",
            type: "number",
            position: "left",
            nice: true,
            keys: [
              "dailyVotes",
              "allTimeVotes",
              "allTimeRating",
              "allTimeRank",
            ],
            title: {
              enabled: false,
              text: "Votes & Ratings",
              fontSize: 10,
              color: "rgb(100 116 139)",
              spacing: 4,
            },
            label: {
              formatter: function (params) {
                return shortenInteger(params.value);
              },
            },
          },
        ],
      };
    },
    [theme, data],
  );

  return { getLineChartOptions };
};
