import React, { useState, useMemo, useEffect, createContext, useRef } from "react";
import moment from "moment";
import _ from "lodash";
import { 
  Container, 
  SpaceBetween, 
  Spinner,
  Tabs,
  ColumnLayout,
} from "@cloudscape-design/components";
import TimeseriesChart from "./components/TimeseriesChart/TimeseriesChart";
import MetricsTable from "./components/MetricsTable/MetricsTable";
import { getTimeseriesForMetrics } from "./utils";
import { DEFAULT_OPTIONS, DEFAULT_DATE_RANGE } from "./constants";
import Parameters from "./components/Parameters/Parameters";
import RadialChart from "./components/RadialChart/RadialChart";
import { useGetComparisonData } from "./api/hooks/useGetComparisonData";
import { useGetTitleSearchByIdData } from "./components/Parameters/api/hooks/useGetTitleSearchByIdData";
import { SummaryRanking } from "./components/SummaryRanking/SummaryRanking";

export const CompareContext = createContext();

const DatapointComparison = ({ 
  metrics, 
  defaultProperties,
  properties: propertiesFromProps,
  setProperties: setPropertiesFromProps,
  options={},
}) => {

  const isControlled = propertiesFromProps !== undefined && setPropertiesFromProps !== undefined;
  const isFirstRender = useRef(true);
  
  const [cachedTitles, setCachedTitles] = useState({});
  const [uncontrolledTitleIds, setUncontrolledTitleIds] = useState(defaultProperties?.parameters?.map(p => p.titleIds ?? []) ?? [[]]);
  const [uncontrolledMetricIds, setUncontrolledMetricIds] = useState(defaultProperties?.metricKeys ?? []);
  const [uncontrolledActiveMetricKey, setUncontrolledActiveMetricKey] = useState(defaultProperties?.activeMetricKey ?? metrics[0]?.key);
  const [uncontrolledDateRanges, setUncontrolledDateRanges] = useState(defaultProperties?.parameters?.map(p => (p.dateRange ? { type: "absolute", ...p.dateRange } : DEFAULT_DATE_RANGE)) ?? [DEFAULT_DATE_RANGE]);

  const titleIds = isControlled ? (propertiesFromProps?.parameters?.map(p => p.titleIds ?? []) ?? [[]]) : uncontrolledTitleIds;
  const metricIds = isControlled ? (propertiesFromProps.metricKeys ?? []) : uncontrolledMetricIds;
  const activeMetricKey = isControlled ? (propertiesFromProps.activeMetricKey ?? metrics[0]?.key) : uncontrolledActiveMetricKey;
  const dateRanges = isControlled ? (propertiesFromProps.parameters?.map(p => (p.dateRange ? { type: "absolute", ...p.dateRange } : DEFAULT_DATE_RANGE)) ?? [DEFAULT_DATE_RANGE]) : uncontrolledDateRanges;

  const titlesToFetchById = titleIds.flat().filter(id => !Object.keys(cachedTitles).includes(id));
  const [externallyLoadedTitles, setExternallyLoadedTitles] = useState(null);

  const {
    data: titleSearchByIdData,
    isLoading: searchByIdIsLoading,
  } = useGetTitleSearchByIdData({ ipIds: titlesToFetchById });

  useEffect(() => {
    if (titleSearchByIdData || externallyLoadedTitles) {
      setCachedTitles(prev => ({
        ...prev,
        ...(titleSearchByIdData ? titleSearchByIdData.reduce((acc, title) => ({ ...acc, [title.ip_id]: title }), {}) : []),
        ...(externallyLoadedTitles ? externallyLoadedTitles.reduce((acc, title) => ({ ...acc, [title.ip_id]: title }), {}) : {}),
      }));
      setExternallyLoadedTitles(null);
    }
  }, [titleSearchByIdData, externallyLoadedTitles]);

  useEffect(() => {
    if (!isControlled && isFirstRender.current && defaultProperties) {
      setUncontrolledTitleIds(defaultProperties?.parameters?.map(p => p.titleIds ?? []) ?? [[]]);
      setUncontrolledMetricIds(defaultProperties?.metricKeys ?? []);
      setUncontrolledActiveMetricKey(defaultProperties?.activeMetricKey ?? null);
      setUncontrolledDateRanges(defaultProperties?.parameters?.map(p => (p.dateRange ? { type: "absolute", ...p.dateRange } : DEFAULT_DATE_RANGE)) ?? [DEFAULT_DATE_RANGE]);
    }
    isFirstRender.current = false;
  }, [defaultProperties, isControlled]);


  const handleSetDateRanges = (newDateRanges) => {
    if (isControlled) {
      setPropertiesFromProps(prev => (
        { 
          ...prev, 
          parameters: prev.parameters ?
            newDateRanges.map((range, i) => ({ ...prev.parameters[i], dateRange: range })) :
            newDateRanges.map(range => ({ dateRange: range })),
        }
      ));
    } else {
      setUncontrolledDateRanges(newDateRanges);
    }
  };

  const handleSetMetricKeys = (newMetricKeys) => {
    if (isControlled) {
      setPropertiesFromProps(prev => ({ ...prev, metricKeys: newMetricKeys }));
    } else {
      setUncontrolledMetricIds(newMetricKeys);
    }
  };

  const handleSetTitleIds = (newTitleIds) => {
    if (isControlled) {
      setPropertiesFromProps(prev => (
        { 
          ...prev, 
          parameters: prev.parameters ?
            newTitleIds.map((ids, i) => ({ ...prev.parameters[i], titleIds: ids })) :
            newTitleIds.map(ids => ({ titleIds: ids })),
        }
      ));
    } else {
      setUncontrolledTitleIds(newTitleIds);
    }
  };

  const handleSetActiveMetricKey = (newActiveMetricKey) => {
    if (isControlled) {
      setPropertiesFromProps(prev => ({ ...prev, activeMetricKey: newActiveMetricKey }));
    } else {
      setUncontrolledActiveMetricKey(newActiveMetricKey);
    }
  };

  const selectedTitles = useMemo(() => titleIds.map(ids => ids.map(id => ({ value: id, ...cachedTitles[id] }))), [titleIds, cachedTitles]);
  const selectedMetrics = useMemo(() => metrics.filter(metric => metricIds.includes(metric.key)), [metrics, metricIds]);
  const allSelectedTitles = useMemo(() => selectedTitles.flat(), [selectedTitles]);
  const fullDateRange = useMemo(() => {
    if (!dateRanges || dateRanges.length === 0) {
      return DEFAULT_DATE_RANGE;
    }
    const earliestStartDate = dateRanges.reduce((earliest, range) => {
      return moment.utc(range.startDate).isBefore(earliest) ? moment.utc(range.startDate) : earliest;
    }, moment.utc());
    const latestEndDate = dateRanges.reduce((latest, range) => {
      return moment.utc(range.endDate).isAfter(latest) ? moment.utc(range.endDate) : latest;
    }, moment.utc());
    return {
      startDate: earliestStartDate.format("YYYY-MM-DD"),
      endDate: latestEndDate.format("YYYY-MM-DD"),
    };
  }, [dateRanges]);



  const [ metricsTimeseriesData, setMetricsTimeseriesData ] = useState([]);
  const [ metricsTableData, setMetricsTableData ] = useState([]);
  const [ crosslineData, setCrosslineData ] = useState([]);
  
  const [ titlesLoading, setTitlesLoading ] = useState(false);

  const globalOptions = useMemo(() => (_.merge({}, DEFAULT_OPTIONS, options)), [options]);

  const multiParamInfo = useMemo(() => {
    if (dateRanges.length !== selectedTitles.length) {
      return [];
    }
    let minStartTs = moment.utc(dateRanges[0].startDate).unix();
    if (dateRanges.length > 1) {
      for (let i = 1; i < dateRanges.length; i++) {
        minStartTs = Math.min(minStartTs, moment.utc(dateRanges[i].startDate).unix());
      }
    }

    let titleDateOffsets = {};
    for (let i = 0; i < dateRanges.length; i++) {
      const range = `${moment.utc(dateRanges[i].startDate).format("YYYY-MM-DD")} - ${moment.utc(dateRanges[i].endDate).format("YYYY-MM-DD")}`;
      if (!titleDateOffsets[range]) {
        titleDateOffsets[range] = {
          offset: (moment.utc(dateRanges[i].startDate).unix() - minStartTs) * 1000,
          title_ids: [],
          start_ts: moment.utc(dateRanges[i].startDate).unix() * 1000,
          end_ts: moment.utc(dateRanges[i].endDate).unix() * 1000,
        };
      }
      titleDateOffsets[range].title_ids = [...titleDateOffsets[range].title_ids, ...selectedTitles[i].map(title => title.value)];
    }
    return Object.values(titleDateOffsets);
  }, [dateRanges, selectedTitles]);

  const { data: comparisonData, isLoading: comparisonDataLoading, } = useGetComparisonData({
    ipIds: [...new Set(allSelectedTitles.map(title => title.value))],
    datapoints: [...new Set(selectedMetrics.map(metric => metric.datapoints.map(datapoint => datapoint.platform.key)).flat())],
    startTs: moment.utc(fullDateRange.startDate).startOf("day").unix(),
    endTs: moment.utc(fullDateRange.endDate).startOf("day").unix(),
  });

  const processComparisonResponse = ({ responseData, metricsToCompare }) => {
    const timeseriesData = getTimeseriesForMetrics(metricsToCompare, responseData.data);
    setMetricsTimeseriesData(timeseriesData);

    const titleMetadata = responseData.metadata;
    const crosslines = [
      ...titleMetadata.map(title => (
        title.events?.map(event => (
          {
            value: moment.utc(event.date).unix() * 1000,
            label: event.label,
            ip_id: title.ip_id,
            ip_name: title.ip,
            type: event.type,
          }
        ))
      )).flat(),
    ];
    setCrosslineData(crosslines);

    const tableData = multiParamInfo.map((paramInfo, paramIndex) => (
      titleMetadata.filter(t => paramInfo.title_ids.includes(t.ip_id)).map(title => {
        return {
          ip_id: title.ip_id,
          param_index: paramIndex,
          ...metricsToCompare.map(metric => {
            const metricId = metric.key;
            const timeseriesKey = `${title.ip_id}_${metricId}`;
            const timeseriesValues = timeseriesData.filter(d =>  d.timestamp >= paramInfo.start_ts && d.timestamp <= paramInfo.end_ts).map(d => d[timeseriesKey]).filter(v => v !== undefined);
            return {
              [metricId]: {
                sum: timeseriesValues.length > 0 ? timeseriesValues.reduce((a, b) => a + b, 0) : null,
                average: timeseriesValues.length > 0 ? timeseriesValues.reduce((a, b) => a + b, 0) / timeseriesValues.length : null,
                max: timeseriesValues.length > 0 ? Math.max(...timeseriesValues) : null,
              },
            };
          }).reduce((acc, val) => ({ ...acc, ...val }), {}),
        };
      })
    )).flat();
    setMetricsTableData(tableData);
  };

  useEffect(() => {
    if (comparisonData) {
      processComparisonResponse({ responseData: comparisonData, metricsToCompare: selectedMetrics });
    }
  }, [comparisonData, selectedMetrics]);

  return (
    <CompareContext.Provider value={globalOptions}>
      <SpaceBetween direction="vertical" size="l">
        <Container
          disableContentPaddings={globalOptions.container.disablePadding}
          disableHeaderPaddings={globalOptions.container.disablePadding}
          variant={globalOptions.container.disableBorder ? "full-page" : "default"}
        >
          <div className="flex flex-col gap-y-4">
            <div className={globalOptions.parameters.enabled ? "" : "hidden"}>
              <Parameters
                metrics={metrics}
                options={globalOptions.parameters}
                setExternallyLoadedTitles={setExternallyLoadedTitles}
                dateRanges={dateRanges}
                setDateRanges={handleSetDateRanges}
                selectedMetrics={selectedMetrics}
                setSelectedMetrics={(metrics) => handleSetMetricKeys(metrics.map(m => m.key))}
                selectedTitles={selectedTitles}
                setSelectedTitles={(titles) => handleSetTitleIds(titles.map(t => t.map(title => title.ip_id)))}
                activeMetricKey={activeMetricKey}
                setActiveMetricKey={handleSetActiveMetricKey}
                onTitlesLoadingChanged={(loading) => {
                  setTitlesLoading(loading);
                }}
              />
            </div>
            <Tabs
              onChange={({ detail }) => handleSetActiveMetricKey(detail.activeTabId)}
              activeTabId={activeMetricKey}
              tabs={[
                ...(globalOptions.summary.enabled ? [{ label: "Summary", id: "summary" }] : []),
                ...selectedMetrics.map(metric => ({ label: metric.name, id: metric.key })),
              ]}
              disableContentPaddings
            />
            {comparisonDataLoading ? (
              <div className={`flex flex-col h-[200px] justify-center items-center text-slate-400`}>
                <Spinner size="large" />
              </div> 
            ) : metricsTimeseriesData?.length == 0 || selectedMetrics?.length === 0 || allSelectedTitles.length === 0 ? (
              <div className={`flex flex-col h-[200px] justify-center items-center text-slate-400`}>
                {selectedMetrics?.length === 0 ? "Select metrics to compare" : allSelectedTitles.length === 0 ? "Select titles to compare" : "No data available"}
              </div> 
            ) : (
              <div className="flex flex-col gap-4">
                {globalOptions.chart.enabled && (
                  activeMetricKey === "summary" ? (
                    <ColumnLayout columns={2}>
                      <RadialChart
                        metrics={selectedMetrics}
                        titles={allSelectedTitles}
                        timeseriesData={metricsTimeseriesData}
                        multiParamInfo={multiParamInfo}
                      />
                      <SummaryRanking
                        metrics={selectedMetrics}
                        titles={allSelectedTitles}
                        timeseriesData={metricsTimeseriesData}
                        multiParamInfo={multiParamInfo}
                      />
                    </ColumnLayout>
                  ) : (
                    <TimeseriesChart
                      metrics={selectedMetrics.filter(metric => activeMetricKey === metric.key)}
                      titles={allSelectedTitles}
                      timeseriesData={metricsTimeseriesData}
                      crosslineData={crosslineData}
                      multiParamInfo={multiParamInfo}
                      unitSuffix={selectedMetrics.find(metric => activeMetricKey === metric.key)?.normalize ? "" : ""}
                      reverseYAxis={selectedMetrics.find(metric => activeMetricKey === metric.key)?.reverseYAxis}
                    />
                  )
                )}
                {globalOptions.table.enabled && (
                  <MetricsTable
                    metrics={selectedMetrics}
                    titles={allSelectedTitles}
                    metricData={metricsTableData}
                    multiParamInfo={multiParamInfo}
                  />
                )}
              </div>
            )}
          </div>
        </Container>
      </SpaceBetween>
    </CompareContext.Provider>
  );

};

export default DatapointComparison;