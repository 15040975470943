import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import {
  getInstagramHashtagsData,
  GetInstagramHashtagsDataType,
} from "../request";

export const useInstagramHashtagsData = ({
  params,
}: GetInstagramHashtagsDataType | null) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_INSTAGRAM_HASHTAGS_DATA, params?.tags],
    () => getInstagramHashtagsData({ params }),
  );
  return {
    data,
    isLoading,
    error,
  };
};
