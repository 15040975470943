import { Modal } from "@cloudscape-design/components";
import DatapointComparison from "../../../../../../components/datapoint-comparison/DatapointComparison";
import { useEffect, useState } from "react";

const useWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

export const CompareModal = ({ visible, setVisible, metrics, titleId, metricKeys }) => {
  const { height: windowHeight } = useWindowDimensions();

  const [properties, setProperties] = useState({});

  useEffect(() => {
    setProperties({
      activeMetricKey: properties.activeMetricKey || metricKeys[0],
      metricKeys: metricKeys,
      parameters: [
        {
          titleIds: [titleId],
        },
      ]
    });
  }, [titleId, metricKeys]);

  return (
    <Modal
      size="max"
      onDismiss={() => setVisible(false)}
      visible={visible}
      header="Compare Titles"
    >
      {visible && (
        <DatapointComparison
          metrics={metrics}
          properties={properties}
          setProperties={setProperties}
          options={{
            container: {
              disablePadding: true,
              disableBorder: true,
            },
            chart: {
              height: windowHeight - 325,
            },
            table: {
              enabled: false,
            },
            summary: {
              enabled: true,
            },
          }}
        />
      )}
    </Modal>
  );
};
