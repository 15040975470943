import { SplitPanel } from "@cloudscape-design/components";
import DatapointComparison from "../../../../../components/datapoint-comparison/DatapointComparison";
import {
  SCORE_COMPARE_METRICS,
  DATA_COMPARE_METRICS,
} from "../../../../item/pages/components/modals/CompareModal/metrics";
import { VERTICALS } from "../../../../../config-global";
import { METRIC_GROUPS } from "../../../../../components/datapoint-comparison/constants";
import { useEffect, useMemo, useState } from "react";
import { applyWeightsToMetrics } from "../../../../../components/datapoint-comparison/utils";

const verticalMetricsMap = {
  [VERTICALS.GAMES]: [
    ...SCORE_COMPARE_METRICS.GAMES,
    ...SCORE_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.GAMES,
  ],
  [VERTICALS.MOVIES]: [
    ...SCORE_COMPARE_METRICS.MOVIES_SERIES,
    ...SCORE_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.MOVIES_SERIES,
  ],
  [VERTICALS.SERIES]: [
    ...SCORE_COMPARE_METRICS.MOVIES_SERIES,
    ...SCORE_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.MOVIES_SERIES,
  ],
};

export const CompareContainer = ({
  vertical,
  ipIds,
  dateRange,
  weightsObject,
  onParametersChanged,
}) => {

  const [properties, setProperties] = useState({});

  const metrics = useMemo(() => {
    const zScoreMetrics = [
      ...applyWeightsToMetrics(
        verticalMetricsMap[vertical].filter(
          (metric) => metric.key === "weighted_score",
        ),
        weightsObject,
      ),
      ...verticalMetricsMap[vertical].filter(
        (metric) => metric.key !== "weighted_score",
      )
    ];
    const nonZScoreMetrics = verticalMetricsMap[vertical].filter(
      (metric) => metric.group !== METRIC_GROUPS.ZSCORE,
    );
    const enabledPlatforms = weightsObject.weights
      .filter((weight) => weight.enabled)
      .map((weight) => weight.platform.key);
    const newMetrics = [...zScoreMetrics, ...nonZScoreMetrics].filter(
      (metric) =>
        !metric._platform || enabledPlatforms.includes(metric._platform.key),
    );
    return newMetrics;
  }, [vertical, weightsObject]);

  useEffect(() => {
    setProperties({
      metricKeys: verticalMetricsMap[vertical]
        .filter((metric) => metric.group === METRIC_GROUPS.ZSCORE)
        .map((metric) => metric.key),
      activeMetricKey: properties.activeMetricKey ?? "summary",
      parameters: [
        {
          dateRange: {
            startDate: dateRange?.startDate,
            endDate: dateRange?.endDate,
          },
          titleIds: ipIds,
        },
      ],
    });
  }, [vertical, ipIds, dateRange]);

  useEffect(() => {
    if (!properties || !properties.parameters) return;
    if (onParametersChanged) {
      onParametersChanged(properties.parameters);
    }
  }, [properties]);

  return (
    <SplitPanel
      header="Compare Titles"
      i18nStrings={{
        preferencesTitle: "Preferences",
        preferencesPositionLabel: "Panel position",
        preferencesPositionDescription:
          "Choose the default position for the split panel.",
        preferencesPositionSide: "Side",
        preferencesPositionBottom: "Bottom",
        preferencesCancel: "Cancel",
        preferencesConfirm: "Confirm",
        closeButtonAriaLabel: "Close panel",
        openButtonAriaLabel: "Open panel",
        resizeHandleAriaLabel: "Resize split panel",
      }}
    >
      <div className="mb-4">
        <DatapointComparison
          metrics={metrics}
          properties={properties}
          setProperties={setProperties}
          options={{
            container: {
              disablePadding: true,
              disableBorder: true,
            },
            parameters: {
              enabled: true,
              variant: "tokens",
              showDateRanges: false,
            },
            chart: {
              height: 400,
            },
            summary: {
              enabled: true,
            },
          }}
        />
      </div>
    </SplitPanel>
  );
};
